import { lazy } from "react";

import { Loader } from "src/components/common";
import { MainLayout, AuthLayout, JobLayout } from "src/layouts";

import LoginPage from "src/pages/Login";
import ProfilePage from "src/pages/Profile";
import ConfirmEmail from "src/pages/ConfirmEmail";
import RegisterPage from "src/pages/Register";
import MiddleJobPage from "src/pages/MiddleJob";
import AuthCallbackPage from "src/pages/AuthCallback";
import PaymentMethodPage from "src/pages/PaymentMethod";
import ForgotPasswordPage from "src/pages/ForgotPassword";
import PaymentHistoryPage from "src/pages/PaymentHistory";
import RedirectLandingPage from "src/pages/RedirectLanding";

const HomePage = Loader(lazy(() => import("src/pages/Home")));
const TeamsPage = Loader(lazy(() => import("src/pages/Teams")));
const SubscriptionPage = Loader(lazy(() => import("src/pages/Subscription")));
const JobDetailPage = Loader(lazy(() => import("src/pages/JobDetail")));
const JobInformationPage = Loader(
  lazy(() => import("src/pages/JobInformation")),
);
const PlansPage = Loader(lazy(() => import("src/pages/Plans")));
const PaymentPage = Loader(lazy(() => import("src/pages/Payment")));
const ViewPlanPage = Loader(lazy(() => import("src/pages/ViewPlan")));
const SubscriptionPlanPage = Loader(
  lazy(() => import("src/pages/SubscriptionPlan")),
);

export const PUBLIC_ROUTER = [
  {
    id: "public-1",
    path: "/sign-in",
    page: LoginPage,
    layout: AuthLayout,
  },
  {
    id: "public-2",
    path: "/sign-up",
    page: RegisterPage,
    layout: AuthLayout,
  },
  {
    id: "public-3",
    path: "/forgot-password",
    page: ForgotPasswordPage,
    layout: AuthLayout,
  },
  {
    id: "public-4",
    path: "/confirm-email",
    page: ConfirmEmail,
    layout: AuthLayout,
  },
  {
    id: "public-5",
    path: "/auth-callback",
    page: AuthCallbackPage,
    layout: AuthLayout,
  },
  {
    id: "public-6",
    path: "/redirect-landing",
    page: RedirectLandingPage,
    layout: AuthLayout,
  },
];

export const PRIVATE_ROUTER = [
  {
    id: "private-1",
    path: "/",
    page: HomePage,
    layout: MainLayout,
  },
  {
    id: "private-2",
    path: "/teams",
    page: TeamsPage,
    layout: MainLayout,
  },
  {
    id: "private-3",
    path: "/subscription",
    page: SubscriptionPage,
    layout: MainLayout,
  },
  {
    id: "private-4",
    path: "/job/:id",
    page: JobDetailPage,
    layout: JobLayout,
  },
  {
    id: "private-5",
    path: "/job-information/:id",
    page: JobInformationPage,
    layout: MainLayout,
  },
  {
    id: "private-6",
    path: "/plans",
    page: PlansPage,
    layout: MainLayout,
  },
  {
    id: "private-7",
    path: "/payment",
    page: PaymentPage,
    layout: MainLayout,
  },
  {
    id: "private-8",
    path: "/subscription/:teamId",
    page: ViewPlanPage,
    layout: MainLayout,
  },
  {
    id: "private-9",
    path: "/subscription/:teamId/plans",
    page: SubscriptionPlanPage,
    layout: MainLayout,
  },
  {
    id: "private-10",
    path: "/payment/:teamId/method",
    page: PaymentMethodPage,
    layout: MainLayout,
  },
  {
    id: "private-11",
    path: "/payment/:teamId/history",
    page: PaymentHistoryPage,
    layout: MainLayout,
  },
  {
    id: "private-12",
    path: "/profile",
    page: ProfilePage,
    layout: MainLayout,
  },
  {
    id: "private-13",
    path: "/middle-job/:teamId",
    page: MiddleJobPage,
    layout: MainLayout,
  },
];
