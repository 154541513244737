// Notifications
import { Bell } from "lucide-react";
import { EventSourcePolyfill } from "event-source-polyfill";
import { useState, useEffect, useRef, useMemo } from "react";

import { cn } from "src/lib/utils";
import { Button } from "src/shadcn/ui/button";
import { baseURL } from "src/services/api";
import { LogoIcon } from "src/assets/icons";
import { ScrollArea } from "src/shadcn/ui/scroll-area";
import { INotification } from "src/models";
import { getNotifications, checkNotification } from "src/services/notification";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "src/shadcn/ui/dropdown";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "src/shadcn/ui/dialog";

interface INotificationWithStatus extends INotification {
  status: "read" | "unread";
}

const Notifications = () => {
  // refs
  const notificationRef = useRef<EventSourcePolyfill | null>(null);

  // states
  const [currentNotification, setCurrentNotification] =
    useState<INotification | null>(null);
  const [notificationWithStatusList, setNotificationWithStatusList] = useState<
    INotificationWithStatus[]
  >([]);

  // functions
  const handleGetNotifications = async () => {
    const res = await getNotifications();

    if (res && res?.length > 0) {
      const newRes: INotificationWithStatus[] = res.map((r) => ({
        ...r,
        status: "unread",
      }));

      setNotificationWithStatusList(newRes);
    }
  };

  const handleCheckNotification = async (id: string) => {
    await checkNotification(id);
    setNotificationWithStatusList((prev) => {
      return prev.map((p) => {
        if (p.id === id) {
          return { ...p, status: "read" };
        }
        return p;
      });
    });
  };

  const handleInitNotification = () => {
    notificationRef.current = new EventSourcePolyfill(
      `${baseURL}/notifications/events`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      },
    );

    notificationRef.current.onopen = () => {
      console.info("successfully connected");
    };

    notificationRef.current.onmessage = (event) => {
      if (event?.data) {
        try {
          const data = JSON.parse(event.data) as INotification;
          if (data) {
            setNotificationWithStatusList((prev) => [
              ...prev,
              {
                ...data,
                status: "unread",
              },
            ]);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    notificationRef.current.onerror = () => {
      notificationRef.current?.close();
    };
  };

  const handleSelectNotification = (notification: INotification) => {
    handleCheckNotification(notification.id);
    setCurrentNotification({ ...notification });
  };

  // memos
  const notificationUnredCount = useMemo(() => {
    return (
      notificationWithStatusList.filter((n) => n?.status === "unread")
        ?.length || 0
    );
  }, [notificationWithStatusList]);

  // effects
  useEffect(() => {
    handleGetNotifications();
    handleInitNotification();

    return () => {
      notificationRef.current?.close();
    };
  }, []);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="relative">
            <Button
              variant="ghost"
              className="border-none h-8 w-8 px-0"
            >
              <Bell className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all" />
              <span className="sr-only">Toggle theme</span>
            </Button>
            {notificationUnredCount > 0 && (
              <div className="absolute top-0 right-0 text-sm font-semibold">
                {notificationUnredCount}
              </div>
            )}
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="center"
          className="w-screen max-w-[360px] p-2"
        >
          <p className="text-lg font-medium">Notifications</p>
          <ScrollArea className="mt-3 h-[450px]">
            {notificationWithStatusList.map((notification) => (
              <div
                key={notification.id}
                className={cn(
                  "flex gap-3 items-start cursor-pointer hover:bg-foreground/10 p-1 px-2 rounded-lg transition-all justify-between",
                )}
                onClick={() => handleSelectNotification(notification)}
              >
                <div className="shrink-0">
                  <LogoIcon
                    width={25}
                    height={25}
                  />
                </div>
                <div className="flex-1">
                  <h3 className="text-sm font-semibold line-clamp-1">
                    {notification?.title}
                  </h3>
                  <p className="text-sm line-clamp-3">
                    {notification?.content}
                  </p>
                </div>
                {notification?.status === "unread" && (
                  <div className="size-2 rounded-full bg-blue-600" />
                )}
              </div>
            ))}
          </ScrollArea>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog
        open={!!currentNotification}
        onOpenChange={() => {
          setCurrentNotification(null);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{currentNotification?.title}</DialogTitle>
            <DialogDescription className="hidden">
              {currentNotification?.content}
            </DialogDescription>
          </DialogHeader>
          <div>
            <p className="text-sm">{currentNotification?.content}</p>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Notifications;
