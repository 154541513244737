import { handleGlobalError } from "src/lib/utils";

import api from "../api";
import { IJob, IListReport, IReport } from "src/models";

export const createJobFirstStep = async (
  title?: string,
  employer?: string,
  ref?: string,
  note?: string,
  teamId?: string,
): Promise<IJob | undefined> => {
  try {
    const res = await api.post(`/jobs`, {
      title,
      employer,
      ref,
      note,
      team_id: teamId,
    });

    return res.data?.job;
  } catch (error) {
    handleGlobalError(error, teamId);
    return undefined;
  }
};

export const updateJobFirstStep = async (
  jobId: string,
  updates: {
    title?: string;
    employer?: string;
    ref?: string;
    note?: string;
    team_id?: string;
  },
) => {
  try {
    const filteredUpdates = Object.fromEntries(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      Object.entries(updates).filter(([_, value]) => value !== undefined),
    );

    const { data } = await api.patch(`/jobs/${jobId}`, filteredUpdates);
    return data?.job;
  } catch (error) {
    handleGlobalError(error, updates.team_id);
    return undefined;
  }
};

export const getJobs = async (teamId: string): Promise<IJob[] | undefined> => {
  try {
    const res = await api.get(`/jobs?team_id=${teamId}`);
    return res.data?.jobs || [];
  } catch (error) {
    handleGlobalError(error, teamId);
    return undefined;
  }
};

export const addDescriptionToJob = async (
  jobId: string,
  description: string,
): Promise<IJob | undefined> => {
  try {
    const res = await api.patch(`/jobs/${jobId}/description`, { description });
    return res.data?.job;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const addFileToJob = async (
  file: File,
  jobId: string,
): Promise<IJob | undefined> => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const res = await api.post(`/jobs/${jobId}/description/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return res.data?.job;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getJobById = async (id: string): Promise<IJob | undefined> => {
  try {
    const res = await api.get(`/jobs/${id}`);
    return res.data?.job;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const uploadResumeFile = async (
  jobId: string,
  files: File[],
  team_id: string,
) => {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append("files", file);
  });

  try {
    const res = await api.post(`/jobs/${jobId}/resumes`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res.data?.job;
  } catch (error) {
    handleGlobalError(error, team_id);
    return undefined;
  }
};

export const deleteResumeFile = async (resumeId: string) => {
  try {
    const res = await api.delete(`/jobs/resumes/${resumeId}`);
    return res.data?.job || {};
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const assessmentCriteriaGenerate = async (jobId: string) => {
  try {
    const res = await api.post(`/jobs/${jobId}/assessment/generate`);
    return res.data?.job;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const updateAssessmentCriteria = async (
  jobId: string,
  mustHave: string[],
  desireHave: string[],
) => {
  try {
    const res = await api.patch(`/jobs/${jobId}/assessment`, {
      assessment_must_have_criteria: mustHave,
      assessment_desire_to_have_criteria: desireHave,
    });
    return res.data?.job;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const generateListReport = async (
  jobId: string,
): Promise<IReport | undefined> => {
  try {
    const res = await api.post(`/jobs/${jobId}/report/generate`);
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return;
  }
};

export const generateInterview = async (jobId: string) => {
  try {
    const res = await api.post(`/jobs/${jobId}/interview/generate`);
    return res.data?.job;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getShortListReport = async (jobId: string) => {
  try {
    const res = await api.get(`/jobs/${jobId}/shortlist-report`);
    return res.data?.report || [];
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getReport = async (
  jobId: string,
): Promise<IReport | undefined> => {
  try {
    const res = await api.get(`/jobs/${jobId}/report`);
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const generateInsight = async (jobId: string, question: string) => {
  try {
    const res = await api.post(`/jobs/${jobId}/insight`, { question });
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const exportJobReport = async (jobId: string, report: IListReport[]) => {
  try {
    const res = await api.post(
      `/jobs/${jobId}/report/export`,
      { report },
      {
        responseType: "arraybuffer",
      },
    );
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const deleteJobById = async (jobId: string) => {
  try {
    const res = await api.delete(`/jobs/${jobId}`);

    if (res.status === 200) {
      return "success";
    }

    return "failed";
  } catch (error) {
    handleGlobalError(error);
    return "error";
  }
};

export const generateChatReportStream = async () => {};
