import { handleGlobalError } from "src/lib/utils";
import api from "../api";
import { IMember } from "src/models";

export const createMemberInvitation = async (
  teamId: string,
  email: string,
  role: string,
) => {
  try {
    const res = await api.post(`/teams/${teamId}/invitations`, { email, role });
    return res.data;
  } catch (error) {
    handleGlobalError(error, teamId);
    return undefined;
  }
};

export const getInvitationsPendingList = async (
  teamId: string,
): Promise<IMember[]> => {
  try {
    const res = await api.get(`/teams/${teamId}/invitations-pending`);
    return res?.data?.result || [];
  } catch (error) {
    handleGlobalError(error, teamId);
    return [];
  }
};

export const deleteInvitationById = async (invitationId: string) => {
  try {
    const res = await api.delete(`/teams/invitations/${invitationId}`);

    if (res.status === 200) {
      return "ok";
    }
    return "fail";
  } catch (error) {
    handleGlobalError(error);
    return "fail";
  }
};

export const resendInvitation = async (invitationId: string) => {
  try {
    const res = await api.post(`/teams/invitations/${invitationId}/resend`);
    return res.data;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const deleteMemberByMemberId = async (memberId: string) => {
  try {
    const res = await api.delete(`/teams/members/${memberId}`);

    if (res.status === 200) {
      return "ok";
    }
    return "fail";
  } catch (error) {
    handleGlobalError(error);
    return "fail";
  }
};

export const getMemberById = async (id: string) => {
  try {
    const res = await api.get(`/${id}`);
    return res;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const updateMemberById = async (id: string, role: string) => {
  try {
    const res = await api.patch(`/teams/members/${id}`, { role });
    if (res.status === 200) {
      return "ok";
    }
    return "fail";
  } catch (error) {
    handleGlobalError(error);
    return "fail";
  }
};
