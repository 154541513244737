import { toast } from "sonner";
import { ColumnDef } from "@tanstack/react-table";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Trash2, Edit2 } from "lucide-react";
import { handleDeleteJob } from "src/store/features/loading";

import React from "react";
import moment from "moment";

import { IJob } from "src/models";
import { Button } from "src/shadcn/ui/button";
import { Checkbox } from "src/shadcn/ui/checkbox";
import { DeleteDialog } from "src/components/common";
import { deleteJobById } from "src/services/job";
import { DataTableColumnHeader } from "./data-table-column-header";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "src/shadcn/ui/tooltip";

export const labels = [
  {
    value: "bug",
    label: "Bug",
  },
  {
    value: "feature",
    label: "Feature",
  },
  {
    value: "documentation",
    label: "Documentation",
  },
];

const DeleteButton = ({ currentJob }: { currentJob: IJob }) => {
  const dispatch = useDispatch();

  // functions
  const handleDeleteJobById = async () => {
    const res = await deleteJobById(currentJob.id);

    if (res === "success") {
      toast.success("Job deleted successfully");
      dispatch(handleDeleteJob(true));
    }
  };

  return (
    <React.Fragment>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <DeleteDialog
              description="This action cannot be undone. This will permanently delete this resume file."
              onAction={() => handleDeleteJobById()}
            >
              <Trash2 className="size-4" />
            </DeleteDialog>
          </TooltipTrigger>
          <TooltipContent className="dark:text-white text-black text-xs">
            Delete Job
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </React.Fragment>
  );
};

const MoveButton = ({ currentJob }: { currentJob: IJob }) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              className="p-1 h-fit"
              onClick={() => navigate(`/middle-job/${currentJob.id}`)}
            >
              <Edit2 className="size-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent className="dark:text-white text-black text-xs">
            Edit job
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </React.Fragment>
  );
};

export const columns: ColumnDef<IJob>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "ref",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Job ID"
        className="font-bold"
      />
    ),
    cell: ({ row }) => <div>{row.getValue("ref")}</div>,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "title",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Job Title"
      />
    ),
    cell: ({ row }) => <div>{row.getValue("title")}</div>,
  },
  {
    accessorKey: "employer",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Employer"
      />
    ),
    cell: ({ row }) => <div>{row.getValue("employer")}</div>,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "created_date",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Created At"
      />
    ),
    cell: ({ row }) => (
      <div>
        {moment.unix(row.getValue("created_date")).format("MM/DD/YYYY")}
      </div>
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "actions",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Actions"
        className="font-bold"
      />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex items-center gap-2">
          <MoveButton currentJob={row.original} />
          <DeleteButton currentJob={row.original} />
        </div>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
];
