import { z } from "zod";
import { toast } from "sonner";
import { useForm } from "react-hook-form";
import { Loader2 } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";

import { diffWords } from "diff";

import { IJob } from "src/models";
import { Button } from "src/shadcn/ui/button";
import { Textarea } from "src/shadcn/ui/textarea";
import { FileUploader } from "src/components/common";
import { jobDescriptionFormSchema } from "src/models/form-schema";
import { addDescriptionToJob, addFileToJob } from "src/services/job";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/shadcn/ui/form";

interface Props {
  jobId: string;
  currentJob: IJob;
  onSaveJobDescription: (
    // eslint-disable-next-line no-unused-vars
    values: IJob,
  ) => void;
}

const formSchema = z
  .object({
    jobDescription: z.string().optional(),
    files: z.array(z.instanceof(File)).optional(),
  })
  .superRefine((data, ctx) => {
    const hasDescription =
      data.jobDescription && data.jobDescription?.length > 0;
    const hasFile = data.files && data.files?.length > 0;

    if (!hasDescription && !hasFile) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["jobDescription"],
        message: "Job description is required",
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["files"],
        message: "Job description file is required",
      });

      return;
    }
  });

const JobDescription: React.FC<Props> = ({
  jobId,
  currentJob,
  onSaveJobDescription,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  // refs
  const isEditRef = useRef<boolean>(false);

  // state
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUploadFileLoading, setIsUploadFileLoading] =
    useState<boolean>(false);

  // schema
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      jobDescription: "",
      files: [],
    },
  });

  const { watch } = form;
  const watchFiles = watch("files");

  // functions
  const handleAddDescription = async (des: string) => {
    if (!jobId) {
      toast.error("Job ID is missing");
      return;
    }

    setIsLoading(true);
    const res = await addDescriptionToJob(jobId, des);
    setIsLoading(false);

    if (res) {
      onSaveJobDescription(res);
      navigate(`${location.pathname}?step=2`);
    }
  };

  const handleAddFile = async (file: File) => {
    if (!jobId) {
      toast.error("Job ID is missing");
      return;
    }

    isEditRef.current = true;

    setIsUploadFileLoading(true);
    const res = await addFileToJob(file, jobId);
    setIsUploadFileLoading(false);

    if (res) {
      toast.success("File uploaded successfully");
      onSaveJobDescription(res);
      form.setValue("files", []); // clear file after upload
    }
  };

  const onSubmit = (
    values: z.infer<ReturnType<typeof jobDescriptionFormSchema>>,
  ) => {
    if (
      values?.jobDescription &&
      values?.jobDescription?.length > 0 &&
      values?.files &&
      values?.files?.length > 0
    ) {
      toast.warning("You can only upload a file or upload text!");
      return;
    }
    const dif = diffWords(
      currentJob?.description || "",
      values.jobDescription || "",
    );
    const hasDifferences = dif.some((part) => part.added || part.removed);

    if (hasDifferences) {
      handleAddDescription(values.jobDescription || "");
    } else {
      navigate(`${location.pathname}?step=2`);
    }
  };

  // effects
  useEffect(() => {
    if (currentJob?.description) {
      form.setValue("jobDescription", currentJob.description);
    }
  }, [currentJob]);

  useEffect(() => {
    if (watchFiles && watchFiles.length > 0) {
      if (isUploadFileLoading) {
        return;
      }

      handleAddFile(watchFiles[0]);
    }
  }, [watchFiles]);

  return (
    <React.Fragment>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          {/* {fileType === FILE_UPLOAD_TYPE.file ? (
            <React.Fragment>
              <div className="w-full h-full flex justify-center items-center">
                <FormField
                  control={form.control}
                  name="files"
                  render={({ field }) => (
                    <FormItem className="col-span-1 md:col-span-2 lg:col-span-3">
                      <FormControl>
                        <FileUploader onChange={field.onChange} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              {watchFiles ? (
                <ul className="w-full mt-8 flex flex-wrap gap-x-12 gap-y-4 px-10">
                  {(watchFiles ?? []).map((file: File, index) => (
                    <FileItem
                      key={`${file.name}-${index}`}
                      file={file}
                      onRemoveFile={() => removeFileByIndex(index)}
                    />
                  ))}
                </ul>
              ) : (
                <p className="text-sm mt-10 text-center">
                  No files have been uploaded
                </p>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <FormField
                control={form.control}
                name="jobDescription"
                render={({ field }) => (
                  <FormItem className="col-span-1 md:col-span-2 lg:col-span-3">
                    <FormLabel>Job Description text</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder="Enter your job description here"
                        className="resize-none"
                        {...field}
                        rows={15}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </React.Fragment>
          )} */}
          <React.Fragment>
            <div className="w-full h-full flex flex-col gap-10">
              <div className="w-full flex flex-col items-center gap-4 rounded-lg py-4 max-w-[450px] mx-auto bg-gray-200/80 dark:bg-black/30">
                <div className="space-y-6">
                  {currentJob?.desc_url && (
                    <div>
                      <ul className="flex flex-wrap gap-x-12 gap-y-4 pl-6">
                        <li className="text-sm list-decimal underline">
                          <a href={currentJob.desc_url}>Job Description File</a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                <FormField
                  control={form.control}
                  name="files"
                  render={({ field }) => (
                    <FormItem className="">
                      <FormControl>
                        <FileUploader
                          isLoading={isUploadFileLoading}
                          className="mt-0"
                          onChange={field.onChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={form.control}
                name="jobDescription"
                render={({ field }) => (
                  <FormItem className="col-span-1 md:col-span-2 lg:col-span-3">
                    <FormLabel className="text-black">
                      Job Description text
                    </FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder="Enter your job description here"
                        className="resize-none"
                        {...field}
                        rows={15}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </React.Fragment>

          {/* <p
            className="text-center text-sm underline cursor-pointer w-full mt-6"
            onClick={handleChangeFileType}
          >
            {fileType !== FILE_UPLOAD_TYPE.file
              ? " Or upload Job Description file instead"
              : "Or paste Job Description text"}
          </p> */}
          <p className="text-center text-sm w-full mt-6">
            {/* {fileType !== FILE_UPLOAD_TYPE.file
              ? " Or upload Job Description file instead"
              : "Or paste Job Description text"} */}
            Upload <strong>Job Description File</strong> or paste&nbsp;
            <strong>Job Description Text</strong>
          </p>

          <div className="flex justify-end items-center gap-4">
            <Button
              variant="outline"
              onClick={() => navigate(`/job-information/${jobId}`)}
            >
              Back
            </Button>
            <Button
              type="submit"
              className="flex items-center gap-1"
              disabled={isLoading}
            >
              {isLoading && <Loader2 className="size-4 animate-spin" />}
              <span>Next step</span>
            </Button>
          </div>
        </form>
      </Form>
    </React.Fragment>
  );
};

export default JobDescription;
