import { toast } from "sonner";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { Label } from "src/shadcn/ui/label";
import { Input } from "src/shadcn/ui/input";
import { Button } from "src/shadcn/ui/button";
import { addPaymentMethod } from "src/services/payment";
import { handleGlobalError } from "src/lib/utils";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/shadcn/ui/tab";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "src/shadcn/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "src/shadcn/ui/dialog";
import { Loader2 } from "lucide-react";

interface Props {
  open?: boolean;
  teamId: string;
  onClose: () => void;
  callback: () => void;
}

interface CardFormProps {
  open?: boolean;
  teamId: string;
  callback: () => void;
  onClose: () => void;
}

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
);

const CardForm = ({ open, teamId, callback, onClose }: CardFormProps) => {
  const elements = useElements();
  const stripe = useStripe();

  // states
  const [holder, setHolder] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // functions
  const handleAddPaymentMethod = async (
    token: string,
    payer: string,
    holder: string,
  ) => {
    const res = await addPaymentMethod(teamId, token, payer, holder);
    setIsLoading(false);

    if (res) {
      toast.success("Payment method added successfully");
      callback();
      onClose();
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (!stripe || !elements) {
        return;
      }

      const cardNumberElement = elements?.getElement(CardNumberElement);
      if (!cardNumberElement) {
        toast.error("Card number is invalid");
        return;
      }

      setIsLoading(true);
      const token = await stripe.createToken(cardNumberElement);
      if (token.error) {
        toast.error(token.error.message);
        setIsLoading(false);
      } else {
        handleAddPaymentMethod(token.token.id, "", holder);
      }
    } catch (error) {
      setIsLoading(false);
      handleGlobalError(error);
    }
  };

  // effects
  useEffect(() => {
    const cardNumberElement = elements?.getElement(CardNumberElement);
    if (cardNumberElement) {
      cardNumberElement.clear();
    }

    const expiryElement = elements?.getElement(CardExpiryElement);
    if (expiryElement) {
      expiryElement.clear();
    }

    const cvcElement = elements?.getElement(CardCvcElement);
    if (cvcElement) {
      cvcElement.clear();
    }
    setHolder("");
  }, [open]);

  return (
    <Card>
      <CardHeader className="hidden">
        <CardTitle>asd</CardTitle>
        <CardDescription>asd</CardDescription>
      </CardHeader>
      <CardContent className="p-4">
        <form
          className="space-y-4"
          onSubmit={handleSubmit}
        >
          <div className="space-y-1">
            <Label>Card Number</Label>
            <div className="border h-10 px-3 flex items-center rounded-md">
              <CardNumberElement
                options={{
                  style: {
                    base: {
                      fontSize: "14px",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                  },
                }}
                className="w-full"
              />
            </div>
          </div>
          <div className="space-y-1">
            <Label>Expiry Date</Label>
            <div className="border h-10 px-3 flex items-center rounded-md">
              <CardExpiryElement
                options={{
                  style: {
                    base: {
                      fontSize: "14px",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                  },
                }}
                className="w-full"
              />
            </div>
          </div>
          <div className="space-y-1">
            <Label>CVC</Label>
            <div className="border h-10 px-3 flex items-center rounded-md">
              <CardCvcElement
                options={{
                  style: {
                    base: {
                      fontSize: "14px",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                  },
                }}
                className="w-full"
              />
            </div>
          </div>
          <div>
            <Label>Card Holder</Label>
            <Input
              placeholder="Full name displayed on card"
              className="focus:ring-0 focus-visible:ring-0 ring-offset-0 focus-visible:ring-offset-0 placeholder:text-[#aab7c4] text-sm"
              onChange={(e) => setHolder(e.target.value)}
            />
          </div>

          <div className="flex items-center gap-3">
            <Button
              className="flex-1 flex items-center gap-2"
              disabled={isLoading}
            >
              {isLoading && <Loader2 className="size-4 animate-spin" />}
              <span>Save</span>
            </Button>
            <Button
              variant="destructive"
              className="flex-1"
              type="button"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
};

const AddPaymentModal = ({ open, teamId, callback, onClose }: Props) => {
  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        onClose();
      }}
    >
      <DialogContent className="sm:max-w-[425px] p-6">
        <DialogHeader>
          <DialogTitle>Add Payment Method</DialogTitle>
          <DialogDescription>
            Make changes to your payment method here. Click save when you're
            done.
          </DialogDescription>
        </DialogHeader>
        <div className="w-full">
          <Tabs
            defaultValue="stripe"
            className="w-full"
          >
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="stripe">Stripe</TabsTrigger>
              {/* <TabsTrigger value="paypal">PayPal</TabsTrigger> */}
            </TabsList>
            <TabsContent value="stripe">
              <Elements stripe={stripePromise}>
                <CardForm
                  open={open}
                  teamId={teamId}
                  callback={callback}
                  onClose={() => {
                    onClose();
                  }}
                />
              </Elements>
            </TabsContent>
            {/* <TabsContent value="paypal">
              <div>Paypal</div>
            </TabsContent> */}
          </Tabs>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddPaymentModal;
