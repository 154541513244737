import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2, Pencil } from "lucide-react";
import React, { useState, useEffect } from "react";

import { Input } from "src/shadcn/ui/input";
import { ITeam } from "src/models";
import { Button } from "src/shadcn/ui/button";
import { updateTeam } from "src/services/team";
import { newTeamFormSchema } from "src/models/form-schema";
import { Card, CardContent } from "src/shadcn/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "src/shadcn/ui/dialog";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "src/shadcn/ui/tooltip";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/shadcn/ui/form";

interface Props {
  currentTeam: ITeam;
  callBack?: () => void;
}

const EditTeamModal = ({ currentTeam, callBack }: Props) => {
  // state
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<z.infer<typeof newTeamFormSchema>>({
    resolver: zodResolver(newTeamFormSchema),
    defaultValues: {
      name: "",
    },
  });

  // functions
  const handleUpdateTeam = async (teamId: string, name: string) => {
    setIsLoading(true);
    const res = await updateTeam(teamId, name);
    setIsLoading(false);

    if (res === "ok") {
      setOpenModal(false);
      form.reset();
      if (callBack) {
        callBack();
      }
    }
  };

  const onSubmit = (values: z.infer<typeof newTeamFormSchema>) => {
    handleUpdateTeam(currentTeam.id, values.name);
  };

  // effects
  useEffect(() => {
    form.setValue("name", currentTeam.name);
  }, [currentTeam]);

  return (
    <React.Fragment>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              className="p-1 h-fit"
              onClick={() => setOpenModal(true)}
            >
              <Pencil className="size-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent className="dark:text-white text-black text-sm">
            Edit team
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <Dialog
        open={openModal}
        onOpenChange={() => setOpenModal(false)}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-base text-left">Edit Team</DialogTitle>
          </DialogHeader>
          <Card className="w-full max-w-[450px]">
            <CardContent className="p-4">
              <div className="w-full">
                <Form {...form}>
                  <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-6"
                  >
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Team Name *</FormLabel>
                          <FormControl>
                            <Input
                              type="text"
                              placeholder="Enter your team name"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage className="text-xs" />
                        </FormItem>
                      )}
                    />

                    <Button
                      type="submit"
                      className="w-full mb-8"
                      disabled={isLoading || !form.formState.isValid}
                    >
                      {isLoading && (
                        <Loader2 className="mr-2 size-5 animate-spin" />
                      )}
                      Save
                    </Button>
                  </form>
                </Form>
              </div>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default EditTeamModal;
