import { LogoIcon } from "src/assets/icons";
import { RegisterForm } from "src/components/features";
import { ScrollArea } from "src/shadcn/ui/scroll-area";

const Register = () => {
  return (
    <div className="w-full flex justify-between items-center gap-4 h-full">
      <ScrollArea className="bg-zinc-900 flex-1 h-screen max-h-screen hidden md:flex">
        <div className="w-full h-screen flex flex-col items-center justify-center">
          <LogoIcon />
          <h1 className="font-bold text-xl md:text-3xl text-white dark:text-white">
            Welcome to Acquiretalent.co
          </h1>
        </div>
      </ScrollArea>
      <div className="flex-1 flex items-center justify-center">
        <RegisterForm />
      </div>
    </div>
  );
};

export default Register;
