import { Trash2, Pencil, MessageSquareMore } from "lucide-react";
import { ColumnDef } from "@tanstack/react-table";

import { ITeam } from "src/models";
import { Checkbox } from "src/shadcn/ui/checkbox";
import { DataTableColumnHeader } from "./data-table-column-header";

export const labels = [
  {
    value: "bug",
    label: "Bug",
  },
  {
    value: "feature",
    label: "Feature",
  },
  {
    value: "documentation",
    label: "Documentation",
  },
];

export const columns: ColumnDef<ITeam>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "jobId",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Team Members"
      />
    ),
    cell: ({ row }) => <div>{row.getValue("jobId")}</div>,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "jobTitle",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Role"
      />
    ),
    cell: ({ row }) => <div>{row.getValue("jobTitle")}</div>,
  },
  {
    accessorKey: "jobEmployer",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Email"
      />
    ),
    cell: ({ row }) => <div>{row.getValue("jobEmployer")}</div>,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Status"
      />
    ),
    cell: ({ row }) => <div>{row.getValue("createdAt")}</div>,
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "actions",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title="Actions"
      />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex items-center gap-2">
          <button>
            <Trash2 className="size-5" />
          </button>
          <button>
            <Pencil className="size-5" />
          </button>
          <button>
            <MessageSquareMore className="size-5" />
          </button>
        </div>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
];
