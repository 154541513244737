import { useNavigate } from "react-router-dom";

import { cn } from "src/lib/utils";

import { LogoIcon } from "src/assets/icons";

interface Props {
  parentClassName?: string;
  className?: string;
  disabledClick?: boolean;
}

const Logo: React.FC<Props> = ({
  className,
  disabledClick,
  parentClassName,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (disabledClick) {
      return;
    }
    navigate("/");
  };

  return (
    <div
      className={cn(
        "flex items-center gap-2 cursor-pointer",
        parentClassName && parentClassName,
      )}
      onClick={handleClick}
    >
      <LogoIcon />
      <h1
        className={cn("font-bold text-xl md:text-2xl", className && className)}
      >
        Acquire Talent
      </h1>
    </div>
  );
};

export default Logo;
