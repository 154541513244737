import { IInterviewQuestion } from "src/models";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "src/shadcn/ui/card";
import {
  Table,
  TableCaption,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "src/shadcn/ui/table";

interface Props {
  interviewQuestionsList: IInterviewQuestion[];
}

const InterviewQuestions = ({ interviewQuestionsList }: Props) => {
  return (
    <>
      <p className="text-sm font-semibold mb-3">Interview questions</p>
      <Card>
        <CardHeader className="hidden">
          <CardTitle>Interview Questions</CardTitle>
          <CardDescription>Short list description</CardDescription>
        </CardHeader>
        <CardContent className="p-0">
          <Table>
            <TableCaption className="hidden">
              A list of your recent invoices.
            </TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="">Category</TableHead>
                <TableHead>Content</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="">
              {interviewQuestionsList &&
                interviewQuestionsList?.length > 0 &&
                interviewQuestionsList.map((interview, i) => (
                  <TableRow key={`interview-question-${i}`}>
                    <TableCell>{interview.category}</TableCell>
                    <TableCell>{interview.content}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
};

export default InterviewQuestions;
