import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";

import { RootState } from "src/store/store";

type Props = {
  children: React.ReactNode;
};

const PrivateRouter: React.FC<Props> = ({ children }) => {
  const currentAuth = useSelector((state: RootState) => state.auth.isLogged);
  const navigate = useNavigate();

  // effect
  useEffect(() => {
    if (!currentAuth) {
      const currentAccessTokenJSON = localStorage.getItem("access_token");

      if (!currentAccessTokenJSON) {
        navigate("/sign-in");
        return;
      }
    }
  }, [currentAuth]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default PrivateRouter;
