import { handleGlobalError } from "src/lib/utils";

import api from "../api";
import { IPlan, ISubscription } from "src/models";

export const getSubscription = async (): Promise<
  ISubscription[] | undefined
> => {
  try {
    const res = await api.get("/subscription");
    return res.data?.result || [];
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getSubscriptionPlans = async (): Promise<IPlan[] | undefined> => {
  try {
    const res = await api.get("/subscription/plans");
    return res.data?.plans || [];
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getSubscriptionByTeamId = async (
  teamId: string,
): Promise<IPlan | undefined> => {
  try {
    const res = await api.get(`/subscription/plans/${teamId}`);
    return res.data?.plan || {};
  } catch (error) {
    handleGlobalError(error, teamId);
    return undefined;
  }
};

export const postSubscriptionResume = async (teamId: string) => {
  try {
    const res = await api.post(`/subscription/${teamId}/resume`);
    return res.data;
  } catch (error) {
    handleGlobalError(error, teamId);
    return undefined;
  }
};

export const postSubscribePlan = async (
  teamId: string,
  planId: string,
  callback_url: string,
) => {
  try {
    const res = await api.post(`/subscription/${teamId}/subscribe`, {
      plan_id: planId,
      callback_url,
    });

    if (res.status === 200) {
      return "ok";
    }

    return undefined;
  } catch (error) {
    handleGlobalError(error, teamId);
    return undefined;
  }
};

export const cancelSubscription = async (
  teamId: string,
  planId: string,
  callback_url: string,
) => {
  try {
    const res = await api.post(`/subscription/${teamId}/cancel`, {
      plan_id: planId,
      callback_url,
    });

    if (res.status === 200) {
      return "ok";
    }
    return "error";
  } catch (error) {
    handleGlobalError(error, teamId);
    return "error";
  }
};

export const resumeSubscription = async (teamId: string) => {
  try {
    const res = await api.post(`/subscription/${teamId}/resume`);

    if (res.status === 200) {
      return "ok";
    }

    return "failed";
  } catch (error) {
    handleGlobalError(error, teamId);
    return "error";
  }
};
