interface IconProps {
  width?: number;
  height?: number;
}

export const LogoIcon = ({ width = 35, height = 35 }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 482 421"
      fill="currentColor"
    >
      <path
        d="M0 396.746L26 351.746H144V396.746H0Z"
        fill="currentColor"
      />
      <path
        d="M214.518 21.6592L265.132 22.2797L51.4642 397.399L0.000360213 396.746L214.518 21.6592Z"
        fill="currentColor"
      />
      <path
        d="M284.999 61.7461L311 106.746L144.108 399.516L93.999 396.494L284.999 61.7461Z"
        fill="currentColor"
      />
      <path
        d="M268.362 123.461L284.979 61.7576L481.5 396.939L426.344 396.939L268.362 123.461Z"
        fill="currentColor"
      />
      <path
        d="M336 351.746H454L481 396.746H336V351.746Z"
        fill="currentColor"
      />
      <path
        d="M268.5 278.246L292.54 230.417L375.467 374.051L336.496 396.551L268.5 278.246Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const UploadFileColorIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={45}
      height={47}
      viewBox="0 0 45 47"
      fill="none"
    >
      <path
        d="M44.2589 30.5215L42.1123 27.1018L43.61 15.8703C43.7301 14.9704 43.4913 14.0569 42.9564 13.3679C42.4553 12.7225 41.7404 12.334 40.9788 12.2879V5.76391C40.9788 2.58558 38.6514 0 35.791 0H27.3675C24.5644 0 23.0759 1.31517 21.9894 2.27556C21.1159 3.04753 20.5886 3.5134 19.4575 3.5134H7.28438C4.73526 3.5134 2.66162 5.75911 2.66162 8.51951V12.2879C1.89968 12.334 1.18497 12.7225 0.683758 13.3679C0.149073 14.0569 -0.0895307 14.9703 0.0303766 15.8702L3.40925 41.209C3.6135 42.7431 4.7988 43.8793 6.19444 43.8793H30.5924C31.25 45.429 32.6693 46.5044 34.311 46.5044H37.1872C39.453 46.5044 41.2964 44.4564 41.2964 41.9387V37.677C42.6693 37.527 43.8851 36.6239 44.5317 35.2456C45.2426 33.7321 45.1382 31.9218 44.2589 30.5215ZM6.05152 12.2829V8.51951C6.05152 7.74376 6.67823 7.27995 7.28429 7.27995H19.4573C21.7722 7.27995 23.0628 6.13971 24.0996 5.22351C25.0563 4.37766 25.7481 3.76655 27.3674 3.76655H35.7908C36.782 3.76655 37.5884 4.66256 37.5884 5.76391V12.2829H6.05152ZM42.1524 33.943C41.8867 34.5086 41.3603 34.8633 40.7861 34.8633H38.6255V41.9564C38.6255 42.9021 37.9353 43.6685 37.0842 43.6685H34.2081C33.357 43.6685 32.667 42.9021 32.667 41.9564V34.8632H30.5061C29.9321 34.8632 29.4056 34.5085 29.1399 33.9429C28.8744 33.3774 28.914 32.6947 29.2423 32.1716L34.3826 23.9849C34.6707 23.5256 35.1424 23.2522 35.6465 23.2522C36.15 23.2522 36.6218 23.5256 36.9102 23.9849L42.0502 32.1718C42.3784 32.6948 42.418 33.3776 42.1524 33.943Z"
        fill="#3366CC"
      />
    </svg>
  );
};

export const AttachmentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="24"
      viewBox="0 0 15 24"
      fill="none"
    >
      <path
        d="M0 8.06971C0 3.61294 3.148 0 7.03125 0C10.9145 0 14.0625 3.61294 14.0625 8.06971V16.4383C14.0625 16.9335 13.7127 17.3349 13.2812 17.3349C12.8498 17.3349 12.5 16.9335 12.5 16.4383V8.06971C12.5 4.60332 10.0516 1.79327 7.03125 1.79327C4.01095 1.79327 1.5625 4.60333 1.5625 8.06971V17.6338C1.5625 19.7796 3.0782 21.5192 4.94792 21.5192C6.8176 21.5192 8.33333 19.7796 8.33333 17.6338V9.26522C8.33333 8.43989 7.75042 7.77083 7.03125 7.77083C6.31208 7.77083 5.72917 8.43989 5.72917 9.26522V16.4383C5.72917 16.9335 5.37937 17.3349 4.94792 17.3349C4.51645 17.3349 4.16667 16.9335 4.16667 16.4383V9.26522C4.16667 7.4495 5.44917 5.97756 7.03125 5.97756C8.61333 5.97756 9.89583 7.4495 9.89583 9.26522V17.6338C9.89583 20.7701 7.68062 23.3125 4.94792 23.3125C2.21526 23.3125 0 20.7701 0 17.6338V8.06971Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const BankCardIcon = () => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M617.1 671.2H148.2c-46.3 0-83.9-37.6-83.9-83.9v-307c0-46.3 37.6-83.9 83.9-83.9h468.9c46.3 0 83.9 37.6 83.9 83.9v306.9c0 46.4-37.5 84-83.9 84z"
        fill="#248CBE"
      />
      <path
        d="M64.3 302.3h636.8v96.1H64.3z"
        fill="#183351"
      />
      <path
        d="M304 531.8H128.3c-12.1 0-22-9.9-22-22s9.9-22 22-22H304c12.1 0 22 9.9 22 22s-9.9 22-22 22zM437.4 601.2H128.3c-12.1 0-22-9.9-22-22s9.9-22 22-22h309.1c12.1 0 22 9.9 22 22s-9.9 22-22 22z"
        fill="#FBFAEE"
      />
      <path
        d="M875.8 827.6H406.9c-46.3 0-83.9-37.6-83.9-83.9v-307c0-46.3 37.6-83.9 83.9-83.9h468.9c46.3 0 83.9 37.6 83.9 83.9v306.9c0 46.4-37.5 84-83.9 84z"
        fill="#F5DB6F"
      />
      <path
        d="M788.3 607.2H387c-12.1 0-22-9.9-22-22s9.9-22 22-22h401.3c12.1 0 22 9.9 22 22s-9.9 22-22 22z"
        fill="#AEB8C4"
      />
      <path
        d="M562.7 688.2H387c-12.1 0-22-9.9-22-22s9.9-22 22-22h175.8c12.1 0 22 9.9 22 22-0.1 12.1-10 22-22.1 22zM696.1 757.6H387c-12.1 0-22-9.9-22-22s9.9-22 22-22h309.1c12.1 0 22 9.9 22 22s-9.9 22-22 22z"
        fill="#FBFAEE"
      />
      <path
        d="M510.4 520.3H401.8c-20.3 0-36.8-16.5-36.8-36.8v-50.8c0-20.3 16.5-36.8 36.8-36.8h108.6c20.3 0 36.8 16.5 36.8 36.8v50.8c0 20.3-16.5 36.8-36.8 36.8z"
        fill="#F3C262"
      />
      <path
        d="M547.2 438.1h-34.3v-42.2H479v124.4h33.9v-47.7h34.3zM402.6 395.9v42.2H365v34.5h37.6v47.7h33.9V395.9z"
        fill="#F7A04D"
      />
      <path
        d="M959.7 577.8V484c-80.6-0.3-148.2-56.4-166.1-131.6h-95.5c19.2 127.2 129.2 225.1 261.6 225.4z"
        fill="#F7F9DD"
      />
    </svg>
  );
};

export const PaypalIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="70px"
      height="50px"
      viewBox="0 0 780 501"
      enableBackground="new 0 0 780 501"
      xmlSpace="preserve"
    >
      <g>
        <path
          fill="#003087"
          d="M168.379,169.853c-8.399-5.774-19.359-8.668-32.88-8.668H83.153c-4.145,0-6.435,2.073-6.87,6.215
		L55.018,300.883c-0.221,1.311,0.107,2.51,0.981,3.6c0.869,1.092,1.962,1.635,3.271,1.635h24.864c4.361,0,6.758-2.068,7.198-6.215
		l5.888-35.986c0.215-1.744,0.982-3.162,2.291-4.254c1.308-1.09,2.944-1.803,4.907-2.13c1.963-0.324,3.814-0.487,5.562-0.487
		c1.743,0,3.814,0.11,6.217,0.327c2.397,0.218,3.925,0.324,4.58,0.324c18.756,0,33.478-5.285,44.167-15.866
		c10.684-10.577,16.032-25.243,16.032-44.004C180.976,184.96,176.774,175.636,168.379,169.853z M141.389,209.933
		c-1.094,7.635-3.926,12.649-8.506,15.049c-4.581,2.403-11.124,3.598-19.629,3.598l-10.797,0.327l5.563-35.007
		c0.434-2.397,1.851-3.597,4.252-3.597h6.218c8.72,0,15.049,1.257,18.975,3.761C141.389,196.574,142.698,201.865,141.389,209.933z"
        />
        <path
          fill="#009CDE"
          d="M720.794,161.185h-24.208c-2.405,0-3.821,1.2-4.253,3.6l-21.267,136.099l-0.328,0.654
		c0,1.096,0.437,2.127,1.311,3.109c0.868,0.98,1.963,1.471,3.27,1.471h21.595c4.138,0,6.429-2.068,6.871-6.215l21.265-133.813
		v-0.325C725.049,162.712,723.627,161.185,720.794,161.185z"
        />
        <path
          fill="#003087"
          d="M428.31,213.856c0-1.088-0.439-2.126-1.306-3.106c-0.875-0.981-1.858-1.474-2.945-1.474h-25.192
		c-2.404,0-4.366,1.096-5.889,3.271l-34.679,51.04l-14.395-49.075c-1.095-3.487-3.492-5.236-7.197-5.236h-24.541
		c-1.093,0-2.074,0.492-2.941,1.474c-0.875,0.98-1.309,2.019-1.309,3.106c0,0.44,2.127,6.871,6.379,19.303
		c4.252,12.435,8.832,25.849,13.74,40.245c4.908,14.393,7.469,22.031,7.688,22.898c-17.886,24.43-26.826,37.517-26.826,39.259
		c0,2.838,1.416,4.254,4.253,4.254h25.192c2.398,0,4.36-1.088,5.889-3.27l83.427-120.399
		C428.092,215.713,428.31,214.953,428.31,213.856z"
        />
        <path
          fill="#009CDE"
          d="M662.887,209.276h-24.866c-3.055,0-4.904,3.6-5.558,10.798c-5.677-8.721-16.031-13.088-31.083-13.088
		c-15.704,0-29.066,5.89-40.077,17.668c-11.016,11.778-16.521,25.631-16.521,41.551c0,12.871,3.761,23.121,11.285,30.752
		c7.525,7.639,17.612,11.451,30.266,11.451c6.323,0,12.757-1.311,19.3-3.926c6.544-2.617,11.665-6.105,15.379-10.469
		c0,0.219-0.222,1.199-0.655,2.943c-0.44,1.748-0.655,3.059-0.655,3.926c0,3.494,1.414,5.234,4.254,5.234h22.576
		c4.138,0,6.541-2.068,7.194-6.215l13.415-85.39c0.215-1.309-0.112-2.507-0.982-3.599
		C665.284,209.823,664.196,209.276,662.887,209.276z M620.193,273.729c-5.562,5.453-12.268,8.178-20.12,8.178
		c-6.328,0-11.449-1.742-15.377-5.234c-3.927-3.484-5.89-8.283-5.89-14.395c0-8.065,2.726-14.886,8.18-20.447
		c5.447-5.562,12.214-8.343,20.285-8.343c6.101,0,11.173,1.8,15.212,5.397c4.032,3.6,6.054,8.563,6.054,14.889
		C628.536,261.625,625.754,268.279,620.193,273.729z"
        />
        <path
          fill="#003087"
          d="M291.231,209.276h-24.865c-3.058,0-4.908,3.6-5.563,10.798c-5.889-8.721-16.25-13.088-31.081-13.088
		c-15.704,0-29.065,5.89-40.078,17.668c-11.016,11.778-16.521,25.631-16.521,41.551c0,12.871,3.763,23.121,11.288,30.752
		c7.525,7.639,17.61,11.451,30.262,11.451c6.104,0,12.433-1.311,18.975-3.926c6.543-2.617,11.778-6.105,15.704-10.469
		c-0.875,2.617-1.309,4.908-1.309,6.869c0,3.494,1.417,5.234,4.253,5.234h22.574c4.141,0,6.543-2.068,7.198-6.215l13.413-85.39
		c0.215-1.309-0.111-2.507-0.981-3.599C293.627,209.823,292.537,209.276,291.231,209.276z M248.535,273.891
		c-5.563,5.35-12.382,8.016-20.447,8.016c-6.329,0-11.4-1.742-15.214-5.234c-3.819-3.484-5.726-8.283-5.726-14.395
		c0-8.065,2.725-14.886,8.18-20.447c5.449-5.562,12.211-8.343,20.284-8.343c6.104,0,11.175,1.8,15.214,5.397
		c4.032,3.6,6.052,8.563,6.052,14.889C256.878,261.844,254.097,268.553,248.535,273.891z"
        />
        <path
          fill="#009CDE"
          d="M540.036,169.853c-8.398-5.774-19.356-8.668-32.879-8.668h-52.019c-4.365,0-6.765,2.073-7.198,6.215
		l-21.265,133.483c-0.221,1.311,0.106,2.51,0.981,3.6c0.866,1.092,1.962,1.635,3.271,1.635h26.826c2.617,0,4.361-1.416,5.235-4.252
		l5.89-37.949c0.216-1.744,0.98-3.162,2.29-4.254c1.309-1.09,2.943-1.803,4.908-2.13c1.962-0.324,3.813-0.487,5.562-0.487
		c1.743,0,3.814,0.11,6.214,0.327c2.399,0.218,3.93,0.324,4.58,0.324c18.759,0,33.479-5.285,44.168-15.866
		c10.687-10.577,16.031-25.243,16.031-44.004C552.632,184.96,548.431,175.636,540.036,169.853z M506.502,223.673
		c-4.799,3.271-11.997,4.906-21.592,4.906l-10.47,0.327l5.563-35.007c0.432-2.397,1.849-3.597,4.252-3.597h5.887
		c4.797,0,8.614,0.218,11.454,0.653c2.831,0.439,5.561,1.799,8.178,4.089c2.619,2.29,3.926,5.618,3.926,9.979
		C513.7,214.185,511.298,220.399,506.502,223.673z"
        />
      </g>
    </svg>
  );
};
