import { z } from "zod";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link, useNavigate } from "react-router-dom";

import { Input } from "src/shadcn/ui/input";
import { Button } from "src/shadcn/ui/button";
import { loginAccount } from "src/services/auth";
import { login as loginStore } from "src/store/features/auth";
import { Card, CardContent, CardHeader, CardTitle } from "src/shadcn/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/shadcn/ui/form";
import SeparatorOr from "./SeparatorOr";
import { GoogleLoginButton, LinkedInLoginButton } from "src/components/common";

const formSchema = z.object({
  email: z.string().email({ message: "Invalid email address." }),
  password: z.string().min(1, { message: "Password is required." }),
});

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  // state
  const [isLoading, setIsLoading] = useState(false);

  // functions
  const handleLoginAccount = async (credentials: {
    email: string;
    password: string;
  }) => {
    setIsLoading(true);
    const res = await loginAccount(credentials);
    setIsLoading(false);

    if (res?.access_token) {
      dispatch(
        loginStore({
          accessToken: res.access_token,
          refreshToken: res.refresh_token,
          user: null,
        }),
      );

      navigate("/");
    }
  };

  function onSubmit(values: z.infer<typeof formSchema>) {
    handleLoginAccount(values);
  }

  return (
    <Card className="w-full max-w-[450px]">
      <CardHeader>
        <CardTitle className="text-xl">Sign In</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="w-full">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="space-y-4 mb-8">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter your email"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Password</FormLabel>
                      <FormControl>
                        <Input
                          type="password"
                          placeholder="Enter your password"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <Button
                type="submit"
                className="w-full mb-4"
                disabled={isLoading || !form.formState.isValid}
              >
                {isLoading && <Loader2 className="mr-2 size-5 animate-spin" />}
                Sign In
              </Button>

              <div>
                <Link
                  to="/forgot-password"
                  className="text-sm underline text-green-500"
                >
                  Forgot password?
                </Link>
              </div>

              <SeparatorOr className="my-6" />

              <div className="mb-6 space-y-4">
                <GoogleLoginButton className="dark:bg-transparent dark:text-white" />
                <LinkedInLoginButton />
              </div>

              <div className="mt-6">
                <p className="text-sm">
                  New to Acquiretalent.co{" "}
                  <Link
                    to="/sign-up"
                    className="underline"
                  >
                    Sign up now
                  </Link>
                </p>
              </div>
            </form>
          </Form>
        </div>
      </CardContent>
    </Card>
  );
};

export default LoginForm;
