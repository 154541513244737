import { toast } from "sonner";
import { Loader2 } from "lucide-react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { cn } from "src/lib/utils";
import { Button } from "src/shadcn/ui/button";
import { AttachmentIcon } from "src/assets/icons";
import { uploadResumeFile } from "src/services/job";
import { IJob, IUploadResumeFileType } from "src/models";

import UploadResumesTable from "./UploadResumesTable";

interface Props {
  currentJob: IJob;
  currentSocketEvent: IUploadResumeFileType | null;
  // eslint-disable-next-line no-unused-vars
  onNext: (values: IJob) => void;
  onBack: () => void;
  // eslint-disable-next-line no-unused-vars
  callback?: (values: IJob) => void;
}

const UploadResumes: React.FC<Props> = ({
  currentJob,
  currentSocketEvent,
  onNext,
  onBack,
  callback,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  // state
  // const [resumes, setResumes] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // functions
  const handleUploadResumesFile = async (files: File[]) => {
    setIsLoading(true);
    const res = await uploadResumeFile(
      currentJob.id,
      files,
      currentJob.team_id,
    );
    setIsLoading(false);

    if (res) {
      onNext(res);
    }
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files?.length > 0) {
      const fileArr = Array.from(files);
      handleUploadResumesFile(fileArr);
    }
  };

  const handleNextStep = async () => {
    if (currentJob?.resumes?.length === 0) {
      toast.warning("Please upload a file");
      return;
    }
    navigate(`${location.pathname}?step=3`);
  };

  return (
    <div>
      <h1 className="mb-6 text-lg font-semibold">Upload applicant resumes</h1>
      {currentJob?.resumes && currentJob?.resumes.length > 0 && (
        <UploadResumesTable
          teamId={currentJob.team_id || ""}
          resumes={currentJob?.resumes}
          currentSocketEvent={currentSocketEvent}
          onUpdateCurrentJob={callback}
        />
      )}
      <div
        className={cn(
          "mt-8 bg-green-500 text-white p-1 rounded-md w-fit",
          isLoading && "bg-green-500/50",
        )}
      >
        <label
          htmlFor="upload_applicant_resumes"
          className={cn(
            "flex gap-1 items-center cursor-pointer",
            isLoading && "pointer-events-none",
          )}
        >
          <input
            id="upload_applicant_resumes"
            type="file"
            className="hidden"
            accept=".pdf,.docs,.docx,.ppt"
            multiple
            onChange={handleChangeFile}
          />
          {isLoading ? (
            <Loader2 className="size-4 animate-spin" />
          ) : (
            <AttachmentIcon />
          )}
          <div className="">
            <span className="text-sm font-semibold">Upload more files</span>
          </div>
        </label>
      </div>
      <div className="mt-6 flex items-center gap-5 justify-end">
        <Button
          variant="outline"
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          className="flex items-center gap-1"
          disabled={isLoading}
          onClick={handleNextStep}
        >
          <span>Next Step</span>
        </Button>
      </div>
    </div>
  );
};

export default UploadResumes;
