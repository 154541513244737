import { toast } from "sonner";
import { Trash } from "lucide-react";
import { RotateCcw } from "lucide-react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { Button } from "src/shadcn/ui/button";
import { RootState } from "src/store/store";
import { DeleteDialog } from "src/components/common";
import { IMember, ITeam } from "src/models";
import {
  getInvitationsPendingList,
  deleteInvitationById,
  resendInvitation,
} from "src/services/member";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "src/shadcn/ui/tooltip";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/shadcn/ui/table";

interface Props {
  team: ITeam;
}

const PendingAcceptTable = ({ team }: Props) => {
  const currentAccount = useSelector((state: RootState) => state.auth.account);

  // state
  const [pendingList, setPendingList] = useState<IMember[]>([]);
  const [resendLoading, setResendLoading] = useState(false);

  // functions
  const handleGetListPendingAccept = async () => {
    const res = await getInvitationsPendingList(team.id);

    if (res?.length > 0) {
      setPendingList(res);
    } else {
      setPendingList([]);
    }
  };

  const handleDeleteInvitationById = async (invitationId?: string) => {
    if (!invitationId) return;
    const res = await deleteInvitationById(invitationId);

    if (res === "ok") {
      handleGetListPendingAccept();
    }
  };

  const handleResendInvitation = async (invitationId?: string) => {
    if (!invitationId) return;
    setResendLoading(true);
    const res = await resendInvitation(invitationId);
    setResendLoading(false);

    if (res) {
      toast.success("Resend invitation successfully!");
    }
  };

  // effect
  useEffect(() => {
    handleGetListPendingAccept();
  }, []);

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Email</TableHead>
          <TableHead>Role</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {pendingList.map((member) => (
          <TableRow key={member.id}>
            <TableCell className="font-medium">{member.email}</TableCell>
            <TableCell className="capitalize">{member.role}</TableCell>
            <TableCell>Pending</TableCell>
            <TableCell>
              {(team.is_admin || !team.is_owner) && (
                <div className="flex items-center">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          disabled={resendLoading}
                          className="p-1 h-fit"
                          onClick={() => handleResendInvitation(member.id)}
                        >
                          <RotateCcw className="size-4" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p className="text-xs dark:text-white text-black">
                          Resend
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div>
                          <DeleteDialog
                            description="This action cannot be undone. This will permanently delete this invitations."
                            onAction={() =>
                              handleDeleteInvitationById(member.id)
                            }
                          >
                            <Trash className="size-4" />
                          </DeleteDialog>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p className="text-xs dark:text-white text-black">
                          Delete
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PendingAcceptTable;
