import { z } from "zod";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Input } from "src/shadcn/ui/input";
import { Button } from "src/shadcn/ui/button";
import { useToast } from "src/shadcn/ui/use-toast";
import { RootState } from "src/store/store";
import { login as loginStore } from "src/store/features/auth";
import {
  resendEmailConfirm,
  confirmEmailAccount,
  loginAccount,
} from "src/services/auth";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/shadcn/ui/form";

const formSchema = z.object({
  otp: z.string().min(4, {
    message: "Code must be at least 4 characters.",
  }),
});

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toast } = useToast();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email") || "";
  const invitationId = searchParams.get("invitation_id") || "";
  const currentAccount = useSelector((state: RootState) => state.auth.account);

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      otp: "",
    },
  });

  // functions
  const handleLoginAccount = async () => {
    if (!currentAccount) {
      toast({
        title: "Something went wrong.",
        description: "Email is required.",
      });
      return;
    }

    setIsLoading(true);
    const res = await loginAccount(currentAccount);
    setIsLoading(false);

    if (res?.access_token) {
      dispatch(
        loginStore({
          accessToken: res.access_token,
          refreshToken: res.refresh_token,
          user: null,
        }),
      );

      navigate("/");
    }
  };

  const handleConfirmEmailAccount = async (code: string) => {
    setIsLoading(true);
    const res = await confirmEmailAccount(email, code, invitationId);
    setIsLoading(false);

    if (res === "ok") {
      handleLoginAccount();
    }
  };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    handleConfirmEmailAccount(values.otp);
  };

  const handleResendCode = async () => {
    if (!email) {
      toast({
        title: "Something went wrong.",
        description: "Email is required.",
      });
    }

    setResendLoading(true);
    const res = await resendEmailConfirm(email);
    setResendLoading(false);

    if (res) {
      toast({
        title: "Successful",
        description: "Code has been sent to your email.",
      });
    }
  };

  return (
    <div className="w-full max-w-[600px] mx-auto">
      <p className="text-sm mb-8">
        Please check your email for the confirmation code to activate your
        Acquiretalent.co account.
      </p>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="space-y-6 mb-6">
            <FormField
              control={form.control}
              name="otp"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Code</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Input code *"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />
          </div>
          <Button
            type="submit"
            className="w-full mb-4"
            disabled={isLoading || !form.formState.isValid}
          >
            {isLoading && <Loader2 className="mr-2 size-5 animate-spin" />}
            Submit
          </Button>
          <Button
            type="button"
            className="w-full"
            variant="outline"
            disabled={resendLoading}
            onClick={handleResendCode}
          >
            {resendLoading && <Loader2 className="mr-2 size-5 animate-spin" />}
            Resend
          </Button>
        </form>
      </Form>
    </div>
  );
};

export default ConfirmEmail;
