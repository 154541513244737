import { handleGlobalError } from "src/lib/utils";
import api from "../api";
import { IPayment, IPaymentHistory, ITeamPaymentMethod } from "src/models";

export const getPayments = async (): Promise<
  ITeamPaymentMethod[] | undefined
> => {
  try {
    const res = await api.get("/payment");
    return res.data?.payments || [];
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getPaymentByTeamId = async (
  teamId: string,
): Promise<IPayment | undefined> => {
  try {
    const res = await api.get(`/payment/${teamId}`);
    return res.data?.payment;
  } catch (error) {
    handleGlobalError(error, teamId);
    return undefined;
  }
};

export const addPaymentMethod = async (
  teamId: string,
  token: string,
  payer: string,
  holder: string,
) => {
  try {
    const res = await api.post(`/payment/${teamId}/add`, {
      token,
      payer,
      holder,
    });
    return res.data;
  } catch (error) {
    handleGlobalError(error, teamId);
    return undefined;
  }
};

export const getPaymentHistory = async (
  teamId: string,
): Promise<IPaymentHistory[] | undefined> => {
  try {
    const res = await api.get(`/payment/${teamId}/history`);
    return res.data?.logs || [];
  } catch (error) {
    handleGlobalError(error, teamId);
    return undefined;
  }
};
