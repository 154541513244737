import { Loader2 } from "lucide-react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getJobById } from "src/services/job";
import { checkLastStepJob } from "src/lib/utils";

const MiddleJob = () => {
  const navigate = useNavigate();
  const { teamId } = useParams<{ teamId: string }>();

  // functions
  const handleGetJob = async () => {
    if (!teamId) return;

    const res = await getJobById(teamId);

    if (res) {
      const step = checkLastStepJob(res);
      navigate(`/job/${res.id}?step=${step}`);
    } else {
      navigate("/");
    }
  };

  // effects
  useEffect(() => {
    handleGetJob();
  }, [teamId]);

  return (
    <div className="w-full h-[40vh] flex items-center justify-center gap-2">
      <Loader2 className="size-10 text-green-500 animate-spin" />
      <span className="text-sm font-semibold">Waiting ...</span>
    </div>
  );
};

export default MiddleJob;
