import { Loader2 } from "lucide-react";

import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";

import { LogoIcon } from "src/assets/icons";

interface Props {
  currentChat: {
    question: string;
    answer: string;
  };
}

const ChatItem = ({ currentChat }: Props) => {
  return (
    <div className="flex flex-col mt-8">
      <div className="flex justify-end mb-3">
        <div className="w-fit bg-[#F4F4F4] dark:bg-[#2F2F2F] px-2 py-1 rounded-lg">
          <p className="text-sm">{currentChat.question}</p>
        </div>
      </div>
      <div className="flex gap-2 items-start max-w-[70%]">
        <div className="shrink-0 p-2 rounded-full bg-green-500/20">
          <LogoIcon
            width={20}
            height={20}
          />
        </div>
        {currentChat.answer ? (
          <div>
            <ReactMarkdown
              // eslint-disable-next-line react/no-children-prop
              children={currentChat.answer}
              remarkPlugins={[remarkGfm]}
              className="render-markdown"
              skipHtml={false}
              rehypePlugins={[rehypeRaw]}
              components={{
                img: ({ src, alt }) => {
                  return (
                    <img
                      src={src}
                      alt={alt}
                      style={{
                        cursor: "pointer",
                      }}
                      // onClick={() => handleClick(src)}
                    />
                  );
                },
              }}
            />
          </div>
        ) : (
          <Loader2 className="size-4 animate-spin" />
        )}
      </div>
    </div>
  );
};

export default ChatItem;
