import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { cn } from "src/lib/utils";
import { MobileNav } from "./MobileNav";
import { ModeToggle } from "src/shadcn/components/mode-toggle";
import { Logo, MobileLogo } from "src/components/common";

import Account from "./Account";
import Notifications from "./Notifications";

const Header = () => {
  const { pathname } = useLocation();

  return (
    <header className="sticky top-0 z-50 w-full border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="flex h-14 items-center">
        <div className="flex gap-8 items-center">
          <Logo parentClassName="hidden md:flex" />
          <MobileLogo className="flex md:hidden" />
          <nav className="hidden md:flex items-center gap-4 text-sm lg:text-base lg:gap-6">
            <Link
              to="/"
              className={cn(
                "transition-colors hover:text-foreground/80",
                pathname === "/"
                  ? "text-foreground underline font-semibold"
                  : "text-black",
              )}
            >
              Jobs
            </Link>
            <Link
              to="/teams"
              className={cn(
                "transition-colors hover:text-foreground/80",
                pathname === "/teams"
                  ? "text-foreground underline font-semibold"
                  : "text-black",
              )}
            >
              Teams
            </Link>

            <Link
              to="/subscription"
              className={cn(
                "transition-colors hover:text-foreground/80",
                pathname === "/subscription"
                  ? "text-foreground underline font-semibold"
                  : "text-black",
              )}
            >
              Subscriptions
            </Link>
          </nav>
        </div>
        <div className="flex-1 flex items-center justify-end gap-1">
          <ModeToggle />
          <Notifications />
          <Account />
          <MobileNav />
        </div>
      </div>
    </header>
  );
};

export default Header;
