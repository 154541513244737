import React, { useState } from "react";
import { Loader2, Trash2 } from "lucide-react";

import { ITeam } from "src/models";
import { Button } from "src/shadcn/ui/button";
import { deleteTeam } from "src/services/team";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
} from "src/shadcn/ui/alert-dialog";

interface Props {
  currentTeam: ITeam;
  callback?: () => void;
}

const DeleteTeamModal = ({ currentTeam, callback }: Props) => {
  // state
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // functions
  const handleDeleteTeam = async () => {
    setLoading(true);
    const res = await deleteTeam(currentTeam.id);
    setLoading(false);

    if (res === "ok") {
      setOpenModal(false);
      if (callback) {
        callback();
      }
    }
  };

  const handleConfirm = () => {
    handleDeleteTeam();
  };

  return (
    <React.Fragment>
      <Button
        variant="ghost"
        className="p-1 h-fit"
        onClick={() => setOpenModal(true)}
      >
        <Trash2 className="size-4" />
      </Button>
      <AlertDialog
        open={openModal}
        onOpenChange={() => setOpenModal(false)}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your
              your team from our servers.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <Button
              disabled={loading}
              onClick={handleConfirm}
            >
              {loading && <Loader2 className="animate-spin" />}
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </React.Fragment>
  );
};

export default DeleteTeamModal;
