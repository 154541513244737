import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

interface ErrorState {
  error: {
    errorStatus: boolean;
    errorMessage: string;
    teamId?: string;
  } | null;
}

const initialState: ErrorState = {
  error: null,
};

export const errorSlice = createSlice({
  name: "errorStore",
  initialState,
  reducers: {
    setError: (
      state,
      action: PayloadAction<{
        errorStatus: boolean;
        errorMessage: string;
        teamId?: string;
      } | null>,
    ) => {
      state.error = action.payload;
    },
  },
});

export const { setError } = errorSlice.actions;
export default errorSlice.reducer;
