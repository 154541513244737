import { z } from "zod";
import { useForm } from "react-hook-form";
import { Loader2 } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { Input } from "src/shadcn/ui/input";
import { Button } from "src/shadcn/ui/button";
import { saveAccount } from "src/store/features/auth";
import { createAccount } from "src/services/auth";
import { IRulePassword } from "src/models";
import { RULES_PASSWORD } from "src/constants";
import { GoogleLoginButton, LinkedInLoginButton } from "src/components/common";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "src/shadcn/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/shadcn/ui/form";

import SeparatorOr from "../LoginForm/SeparatorOr";

const formSchema = z.object({
  email: z
    .string()
    .min(1, {
      message: "Email is required.",
    })
    .email({ message: "Invalid email address." }),
  password: z.string().min(1, { message: "Password is required." }),
});

const RegisterForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [rulesPassword, setRulesPassword] =
    useState<IRulePassword[]>(RULES_PASSWORD);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { watch } = form;
  const passwordWatch = watch("password");

  // functions
  const handleCreateAccount = async (credentials: {
    email: string;
    password: string;
  }) => {
    setIsLoading(true);
    const res = await createAccount(credentials.email, credentials.password);
    setIsLoading(false);

    if (res === "ok") {
      dispatch(saveAccount(credentials));
      const searchParams = new URLSearchParams();
      searchParams.append("email", credentials.email);

      navigate({
        pathname: "/confirm-email",
        search: searchParams.toString(),
      });
    }
  };

  function onSubmit(values: z.infer<typeof formSchema>) {
    handleCreateAccount(values);
  }

  // effect
  useEffect(() => {
    setRulesPassword((prev) =>
      prev.map((rule) => ({
        ...rule,
        success: rule.pattern.test(passwordWatch),
      })),
    );
  }, [passwordWatch]);

  return (
    <Card className="w-full max-w-[450px]">
      <CardHeader>
        <CardTitle className="text-xl">Sign Up</CardTitle>
        <CardDescription>
          You'll get access to most out of our features, updates and more!
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="w-full">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="space-y-4 mb-8">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter your email"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div>
                  <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Password</FormLabel>
                        <FormControl>
                          <Input
                            type="password"
                            placeholder="Enter your password"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <div className="grid grid-cols-2 mt-2 gap-y-1">
                    {rulesPassword.map((rule) => (
                      <p
                        key={rule.code}
                        className={`text-xs ${
                          rule.success ? "text-green-500" : "text-gray-400/85"
                        }`}
                      >
                        {rule.message}
                      </p>
                    ))}
                  </div>
                </div>
              </div>

              <Button
                type="submit"
                className="w-full mb-2"
                disabled={
                  isLoading ||
                  !form.formState.isValid ||
                  !rulesPassword.every((rule) => rule.success)
                }
              >
                {isLoading && <Loader2 className="mr-2 size-5 animate-spin" />}
                Agree & Sign Up
              </Button>

              <SeparatorOr className="my-6" />

              <div className="mb-6 space-y-4">
                <GoogleLoginButton className="dark:bg-transparent dark:text-white" />
                <LinkedInLoginButton />
              </div>

              <p className="text-xs mt-6">
                By clicking Agree & Sign up, you agree to Acquiretalent.co Terms
                of Service, Privacy & Security Policy and Cookie Policy
              </p>

              <div className="mt-6">
                <p className="text-sm">
                  Already on Acquiretalent.co{" "}
                  <Link
                    to="/sign-in"
                    className="underline text-sm"
                  >
                    Sign in
                  </Link>
                </p>
              </div>
            </form>
          </Form>
        </div>
      </CardContent>
    </Card>
  );
};

export default RegisterForm;
