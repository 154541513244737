import { z } from "zod";

import { FILE_UPLOAD_TYPE } from "src/constants";

export const jobDescriptionFormSchema = (
  fileType: string,
  currentDescription: string,
) =>
  !currentDescription && fileType === FILE_UPLOAD_TYPE.file
    ? z.object({
        jobDescription: z.string().optional(),
        files: z
          .array(z.instanceof(File))
          .min(1, { message: "At least one file is required." }),
      })
    : !currentDescription && fileType === FILE_UPLOAD_TYPE.text
    ? z.object({
        jobDescription: z
          .string()
          .min(1, { message: "Job description is required." }),
        files: z.array(z.instanceof(File)).optional(),
      })
    : z.object({
        jobDescription: z.string().optional(),
        files: z.array(z.instanceof(File)).optional(),
      });

export const newTeamFormSchema = z.object({
  name: z.string().min(1, { message: "This field is required!" }),
});

export const createInvitationFormSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
  role: z.string().min(1, { message: "Please select a role" }),
});
