import { z } from "zod";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";

import { Input } from "src/shadcn/ui/input";
import { Button } from "src/shadcn/ui/button";
import { Textarea } from "src/shadcn/ui/textarea";
import { getTeams } from "src/services/team";
import { zodResolver } from "@hookform/resolvers/zod";
import { IJobCreateNew, ITeam } from "src/models";
import { Card, CardContent, CardHeader, CardTitle } from "src/shadcn/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/shadcn/ui/select";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/shadcn/ui/form";
import { Loader2 } from "lucide-react";

const formSchema = z.object({
  jobId: z.string(),
  jobTitle: z.string(),
  jobEmployer: z.string(),
  currentTeam: z.string().min(1, { message: "Please select a team" }),
  note: z.string().optional(),
});

interface Props {
  id?: string;
  isLoading?: boolean;
  // eslint-disable-next-line no-unused-vars
  handleNext: (values: IJobCreateNew) => void;
}

const JobInformation: React.FC<Props> = ({ id, isLoading, handleNext }) => {
  // state
  const [teams, setTeams] = useState<ITeam[]>([]);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      jobId: "",
      jobTitle: "",
      jobEmployer: "",
      currentTeam: "",
      note: "",
    },
  });

  // functions
  const handleGetTeams = async () => {
    const res = await getTeams();

    if (res?.length > 0) {
      setTeams(res);
    }
  };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    handleNext(values);
  };

  useEffect(() => {
    handleGetTeams();
  }, []);

  return (
    <Card className="w-full h-full border-none shadow-none">
      <CardHeader>
        <CardTitle className="text-xl">
          {!id || id === "new" ? "Create new job" : "Edit job"}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="w-full">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                <FormField
                  control={form.control}
                  name="currentTeam"
                  render={({ field }) => (
                    <FormItem className="min-w-52">
                      <FormLabel>Select team *</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select a team" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {teams.map((team) => (
                            <SelectItem
                              key={team.id}
                              value={team.id}
                            >
                              {team.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                        <FormMessage className="text-xs" />
                      </Select>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="jobId"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Job ID *</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="123 (user enter)"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage className="text-xs" />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="jobTitle"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Job title *</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="BA (user enter)"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage className="text-xs" />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="jobEmployer"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Employer *</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="BA (user enter)"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage className="text-xs" />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="note"
                  render={({ field }) => (
                    <FormItem className="col-span-1 md:col-span-2 lg:col-span-3">
                      <FormLabel>Note</FormLabel>
                      <FormControl>
                        <Textarea
                          placeholder="Note for this job"
                          className="resize-none"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="mt-8 text-right">
                <Button
                  className=""
                  type="submit"
                  disabled={isLoading || !form.formState.isValid}
                >
                  {isLoading && <Loader2 className="size-4 animate-spin" />}
                  Next step
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </CardContent>
    </Card>
  );
};

export default JobInformation;
