import { Loader2 } from "lucide-react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { login as loginStore } from "src/store/features/auth";

const RedirectLanding = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect
  useEffect(() => {
    if (token) {
      dispatch(
        loginStore({ accessToken: token, refreshToken: "", user: null }),
      );
      navigate("/");
    }
  }, [token]);

  return (
    <div className="h-full w-full flex items-center justify-center">
      <Loader2 className="mr-2 size-5 animate-spin" />
      <p className="text-lg">Waiting...</p>
    </div>
  );
};

export default RedirectLanding;
