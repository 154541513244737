import { useParams } from "react-router-dom";
import { Lightbulb, Loader2 } from "lucide-react";
import { useEffect, useState } from "react";

import { Button } from "src/shadcn/ui/button";
import { IPaymentHistory } from "src/models";
import { getPaymentHistory } from "src/services/payment";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "src/shadcn/ui/hover-card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/shadcn/ui/table";

import StripeChannelSvg from "src/assets/images/stripe-icon.svg";
import { Badge } from "src/shadcn/ui/badge";

const PaymentHistory = () => {
  const { teamId } = useParams<{ teamId: string }>();

  // states
  const [loading, setLoading] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState<IPaymentHistory[]>([]);

  // functions
  const handleGetPaymentHistory = async () => {
    if (!teamId) return;

    setLoading(true);
    const res = await getPaymentHistory(teamId);
    setLoading(false);

    if (res && res?.length > 0) {
      setPaymentHistory(res);
    }
  };

  // effects
  useEffect(() => {
    handleGetPaymentHistory();
  }, [teamId]);

  return (
    <>
      <div className="flex justify-between">
        <div className="flex items-center gap-3">
          <h1 className="font-bold text-lg md:text-xl">My Payment History</h1>
          <HoverCard>
            <HoverCardTrigger asChild>
              <Button
                variant="ghost"
                className="p-1 h-fit w-fit"
              >
                <Lightbulb className="size-5" />
              </Button>
            </HoverCardTrigger>
            <HoverCardContent>
              <div className="">
                <h2 className="font-bold text-base">My History</h2>
                <p className="text-sm">
                  Here you can see all the teams you are a part of.
                </p>
              </div>
            </HoverCardContent>
          </HoverCard>
        </div>
      </div>
      {loading && (
        <div className="h-[40vh] flex items-center justify-center">
          <Loader2 className="size-10 text-green-500 animate-spin" />
        </div>
      )}
      {!loading && !paymentHistory && (
        <div className="h-[40vh] flex items-center justify-center">
          <p className="text-lg font-semibold">No Payment History Found</p>
        </div>
      )}

      {!loading && paymentHistory?.length > 0 && (
        <Table className="mt-9">
          <TableHeader>
            <TableRow>
              <TableHead className="pl-0">Payment Method</TableHead>
              <TableHead>Amount</TableHead>
              <TableHead>Transaction Id</TableHead>
              <TableHead>Payment Item</TableHead>
              <TableHead>Payment Date</TableHead>
              <TableHead>Result</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {paymentHistory.map((history) => (
              <TableRow
                key={history.id}
                className=""
              >
                <TableCell className="pl-0 py-1">
                  <img
                    src={StripeChannelSvg}
                    alt="stripe"
                    className="size-8"
                  />
                </TableCell>
                <TableCell className="font-medium py-1">
                  {history.amount}
                </TableCell>
                <TableCell className="py-1">{history.transaction_id}</TableCell>
                <TableCell className="py-1">{history.payment_item}</TableCell>
                <TableCell className="py-1">{history.payment_date}</TableCell>
                <TableCell className="py-1">
                  <Badge
                    variant={
                      history.result === "Success" ? "activate" : "destructive"
                    }
                  >
                    {history.result}
                  </Badge>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default PaymentHistory;
