import { z } from "zod";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";

import { Input } from "src/shadcn/ui/input";
import { Button } from "src/shadcn/ui/button";
import { useToast } from "src/shadcn/ui/use-toast";
import { forgotPassword } from "src/services/auth";
import { ResetPasswordForm } from "src/components/features";
import { Dialog, DialogContent, DialogTitle } from "src/shadcn/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/shadcn/ui/form";

const formSchema = z.object({
  email: z.string().min(4, {
    message: "Code must be at least 4 characters.",
  }),
});

const ForgotPassword = () => {
  const { toast } = useToast();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const [openResetPassword, setOpenResetPassword] = useState(false);

  // functions
  const submitForgotPassword = async (email: string) => {
    setIsLoading(true);
    const res = await forgotPassword(email);
    setIsLoading(false);

    if (res) {
      toast({
        title: "Success",
        description: "Password reset code sent to your email!",
      });
      setOpenResetPassword(true);
      setCurrentEmail(email);
    }
  };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    submitForgotPassword(values.email);
  };

  const handleCloseModal = () => {
    setOpenResetPassword(false);
  };

  return (
    <div className="w-full max-w-[600px] mx-auto">
      <h1 className="mb-2 font-semibold text-xl">Password Reset</h1>
      <p className="text-sm mb-6">
        Enter your e-mail address below, and we'll send you a code to reset your
        password.
      </p>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="space-y-6 mb-6">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter your email address *"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />
          </div>
          <Button
            type="submit"
            className="w-full mb-4"
            disabled={isLoading || !form.formState.isValid}
          >
            {isLoading && <Loader2 className="mr-2 size-5 animate-spin" />}
            Send
          </Button>

          <p className="text-sm text-muted-foreground">
            Please contact us if you have any trouble resetting your password.
          </p>
        </form>
      </Form>

      <Dialog
        open={openResetPassword}
        onOpenChange={setOpenResetPassword}
      >
        <DialogContent className="rounded-lg">
          <DialogTitle>Reset code sent to your email</DialogTitle>
          <ResetPasswordForm
            email={currentEmail}
            onClose={handleCloseModal}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ForgotPassword;
