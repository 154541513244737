import { handleGlobalError } from "src/lib/utils";
import api from "../api";
import { INotification } from "src/models";

export const getNotifications = async (): Promise<
  INotification[] | undefined
> => {
  try {
    const res = await api.get("/notifications");
    return res.data?.notifications || [];
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const checkNotification = async (id: string) => {
  try {
    const res = await api.post(`/notifications/${id}/check`);
    if (res.status === 200) {
      return "ok";
    }
    return "failed";
  } catch (error) {
    handleGlobalError(error);
    return "error";
  }
};
