import { z } from "zod";
import { toast } from "sonner";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState } from "react";
import { Loader2, UserRoundPlus } from "lucide-react";

import { Input } from "src/shadcn/ui/input";
import { Button } from "src/shadcn/ui/button";
import { Card, CardContent } from "src/shadcn/ui/card";
import { createMemberInvitation } from "src/services/member";
import { createInvitationFormSchema } from "src/models/form-schema";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "src/shadcn/ui/dialog";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "src/shadcn/ui/tooltip";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/shadcn/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/shadcn/ui/select";

interface Props {
  teamId: string;
}

const CreateInvitationModal = ({ teamId }: Props) => {
  // state
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<z.infer<typeof createInvitationFormSchema>>({
    resolver: zodResolver(createInvitationFormSchema),
    defaultValues: {
      email: "",
      role: "member",
    },
  });

  // functions
  const handleCreateMemberInvitation = async (email: string, role: string) => {
    setIsLoading(true);
    const res = await createMemberInvitation(teamId, email, role);
    setIsLoading(false);

    if (res) {
      setOpenModal(false);
      form.reset();
      toast.success("Invitation created successfully!");
    }
  };

  const onSubmit = (values: z.infer<typeof createInvitationFormSchema>) => {
    handleCreateMemberInvitation(values.email, values.role);
  };

  return (
    <React.Fragment>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              className="p-1 h-fit"
              onClick={() => setOpenModal(true)}
            >
              <UserRoundPlus className="size-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent className="dark:text-white text-black text-sm">
            Invite a new member to your team
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <Dialog
        open={openModal}
        onOpenChange={() => setOpenModal(false)}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-base text-left">
              Create Invitation
            </DialogTitle>
          </DialogHeader>
          <Card className="w-full max-w-[450px]">
            <CardContent className="p-4">
              <div className="w-full">
                <Form {...form}>
                  <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-6"
                  >
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Email *</FormLabel>
                          <FormControl>
                            <Input
                              type="text"
                              placeholder="Enter a email address"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage className="text-xs" />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="role"
                      render={({ field }) => (
                        <FormItem className="min-w-52">
                          <FormLabel>Select role *</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select a role" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              <SelectItem value="admin">Admin</SelectItem>
                              <SelectItem value="member">Member</SelectItem>
                            </SelectContent>
                            <FormMessage className="text-xs" />
                          </Select>
                        </FormItem>
                      )}
                    />

                    <Button
                      type="submit"
                      className="w-full mb-8"
                      disabled={isLoading || !form.formState.isValid}
                    >
                      {isLoading && (
                        <Loader2 className="mr-2 size-5 animate-spin" />
                      )}
                      Save
                    </Button>
                  </form>
                </Form>
              </div>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default CreateInvitationModal;
