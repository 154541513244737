import { z } from "zod";
import { useForm } from "react-hook-form";
import { Loader2 } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { cn } from "src/lib/utils";
import { Input } from "src/shadcn/ui/input";
import { Button } from "src/shadcn/ui/button";
import { useToast } from "src/shadcn/ui/use-toast";
import { IRulePassword } from "src/models";
import { resetPassword } from "src/services/auth";
import { RULES_PASSWORD } from "src/constants";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/shadcn/ui/form";

const formSchema = z.object({
  code: z.string().min(4, {
    message: "Code must be at least 4 characters.",
  }),
  newPassword: z.string({ message: "Password is required." }),
});

type Props = {
  email: string;
  onClose: () => void;
};

const ResetPasswordForm: React.FC<Props> = ({ email, onClose }) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [rulesPassword, setRulesPassword] =
    useState<IRulePassword[]>(RULES_PASSWORD);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: "",
      newPassword: "",
    },
  });
  const { watch } = form;
  const watchPassword = watch("newPassword");

  // functions
  const handleValidatePassword = (password: string) => {
    setRulesPassword((prev) => {
      return prev.map(
        (rule) =>
          ({
            ...rule,
            success: rule.pattern.test(password),
          } as IRulePassword),
      );
    });
  };

  const handleResetPassword = async (code: string, newPassword: string) => {
    setIsLoading(true);
    const res = await resetPassword(email, code, newPassword);
    setIsLoading(false);

    if (res) {
      onClose();
      toast({
        title: "Success",
        description: "Password reset successful!",
      });
      navigate("/sign-in");
    }
  };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    handleResetPassword(values.code, values.newPassword);
  };

  // effect
  useEffect(() => {
    handleValidatePassword(watchPassword);
  }, [watchPassword]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="space-y-4 mb-8">
          <FormField
            control={form.control}
            name="code"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Code</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Input code *"
                    {...field}
                  />
                </FormControl>
                <FormMessage className="text-xs" />
              </FormItem>
            )}
          />

          <div>
            <FormField
              control={form.control}
              name="newPassword"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>New password</FormLabel>
                  <FormControl>
                    <Input
                      type="password"
                      placeholder="Input new password *"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="text-xs" />
                </FormItem>
              )}
            />
            <ul className="grid grid-cols-2 mt-3 gap-y-2">
              {rulesPassword.map((rule) => (
                <li
                  key={rule.code}
                  className={cn("text-xs", rule.success && "text-green-500")}
                >
                  {rule.message}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Button
          type="submit"
          className="w-full mb-4"
          disabled={isLoading || !form.formState.isValid}
        >
          {isLoading && <Loader2 className="mr-2 size-5 animate-spin" />}
          Confirm
        </Button>
      </form>
    </Form>
  );
};

export default ResetPasswordForm;
