import { Trash } from "lucide-react";

import { ITeam } from "src/models";
import { DeleteDialog } from "src/components/common";
import { deleteMemberByMemberId } from "src/services/member";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "src/shadcn/ui/tooltip";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/shadcn/ui/table";
import EditMemberModal from "../EditMemberModal";

interface Props {
  team: ITeam;
  callback?: () => void;
}

const TeamTable = ({ team, callback }: Props) => {
  // functions
  const handleDeleteMemberById = async (memberId?: string) => {
    if (!memberId) {
      return;
    }
    const res = await deleteMemberByMemberId(memberId);

    if (res === "ok" && callback) {
      callback();
    }
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Team Members</TableHead>
          <TableHead>Role</TableHead>
          <TableHead>Email</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {team.members?.map((member) => (
          <TableRow key={member.id}>
            <TableCell className="font-medium">{member.first_name}</TableCell>
            <TableCell className="capitalize">{member.role}</TableCell>
            <TableCell>{member.email}</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>
              {(team.is_admin || !team.is_owner) && (
                <div className="flex items-center">
                  <EditMemberModal member={member} />
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div>
                          <DeleteDialog
                            description="This action cannot be undone. This will permanently delete this member."
                            onAction={() => handleDeleteMemberById(member.id)}
                          >
                            <Trash className="size-4" />
                          </DeleteDialog>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p className="text-sm">Delete</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TeamTable;
