import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircleUserRound } from "lucide-react";

import { logoutAccount } from "src/services/auth";
import { Button } from "src/shadcn/ui/button";
import { logout as logoutStore } from "src/store/features/auth";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/shadcn/ui/dropdown";

const Account = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // functions
  const handleLogout = async () => {
    await logoutAccount();
    dispatch(logoutStore());
    navigate("/sign-in");
  };

  const handleRedirect = (path: string) => {
    navigate(path);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
        >
          <CircleUserRound className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all" />
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onSelect={() => handleRedirect("/profile")}>
          Profile
        </DropdownMenuItem>
        <DropdownMenuItem onSelect={() => handleRedirect("/payment")}>
          My Payment
        </DropdownMenuItem>
        <DropdownMenuItem
          onSelect={() => {
            handleLogout();
          }}
        >
          Logout
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default Account;
