import { ICreateTeam, ITeam } from "src/models";

import api from "../api";
import { handleGlobalError } from "src/lib/utils";

export const createTeam = async (
  payload: ICreateTeam,
): Promise<string | undefined> => {
  try {
    const res = await api.post("/teams", { name: payload.name });
    if (res.status === 200) {
      return "ok";
    }
    return "fail";
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};

export const getTeams = async (): Promise<ITeam[]> => {
  try {
    const res = await api.get("/teams");
    return res.data?.teams || [];
  } catch (error) {
    handleGlobalError(error);
    return [];
  }
};

export const updateTeam = async (teamId: string, name: string) => {
  try {
    const res = await api.patch(`/teams/${teamId}`, { name });

    if (res.status === 200) {
      return "ok";
    }
    return "fail";
  } catch (error) {
    handleGlobalError(error, teamId);
    return undefined;
  }
};

export const deleteTeam = async (teamId: string) => {
  try {
    const res = await api.delete(`/teams/${teamId}`);

    if (res.status === 200) {
      return "ok";
    }
    return "fail";
  } catch (error) {
    handleGlobalError(error, teamId);
    return undefined;
  }
};

export const getTeamQuota = async (teamId: string) => {
  try {
    const res = await api.get(`/teams/${teamId}/quota`);
    return res.data;
  } catch (error) {
    handleGlobalError(error, teamId);
    return undefined;
  }
};
