import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Lightbulb, Loader2 } from "lucide-react";
import { useEffect, useState } from "react";

import moment from "moment";

import { Button } from "src/shadcn/ui/button";
import { getTeams } from "src/services/team";
import { RootState } from "src/store/store";
import { IPayment, ITeam } from "src/models";
import { AddPaymentModal } from "src/components/features";
import { getPaymentByTeamId } from "src/services/payment";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "src/shadcn/ui/hover-card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/shadcn/ui/table";

import StripeChannelSvg from "src/assets/images/stripe-icon.svg";

const PaymentMethod = () => {
  const { teamId } = useParams();
  const currentUser = useSelector((state: RootState) => state.auth.user);

  // states
  const [teams, setTeams] = useState<ITeam[]>([]);
  const [payment, setPayment] = useState<IPayment | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false);

  // functions
  const handleGetPayment = async () => {
    if (!teamId) return;

    setLoading(true);
    const res = await getPaymentByTeamId(teamId);
    setLoading(false);

    if (res) {
      setPayment(res);
    }
  };

  const handleGetTeams = async () => {
    const res = await getTeams();

    if (res && res?.length > 0) {
      setTeams(res);
    }
  };

  useEffect(() => {
    handleGetPayment();
    handleGetTeams();
  }, [teamId]);

  return (
    <>
      <div className="flex justify-between">
        <div className="flex items-center gap-3">
          <h1 className="font-bold text-lg md:text-xl">My Payment Method</h1>
          <HoverCard>
            <HoverCardTrigger asChild>
              <Button
                variant="ghost"
                className="p-1"
              >
                <Lightbulb className="size-5" />
              </Button>
            </HoverCardTrigger>
            <HoverCardContent>
              <div className="">
                <h2 className="font-bold text-base">My Payment</h2>
                <p className="text-sm">
                  Here you can see all the teams you are a part of.
                </p>
              </div>
            </HoverCardContent>
          </HoverCard>
        </div>
        <Button onClick={() => setOpenAddPaymentModal(true)}>
          {payment ? "Update Payment Method" : "Add Payment Method"}
        </Button>
      </div>
      {loading && (
        <div className="h-[40vh] flex items-center justify-center">
          <Loader2 className="size-10 text-green-500 animate-spin" />
        </div>
      )}
      {!loading && !payment && (
        <div className="h-[40vh] flex items-center justify-center">
          <p className="text-lg font-semibold">No Payment Method Found</p>
        </div>
      )}

      {!loading && payment && (
        <Table className="mt-9">
          <TableHeader>
            <TableRow>
              <TableHead className="pl-0">Team</TableHead>
              <TableHead>User</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Payment Channel</TableHead>
              <TableHead>First Payment Date</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow className="">
              <TableCell className="pl-0 py-1">
                {teams?.find((team) => team.id === teamId)?.name}
              </TableCell>
              <TableCell className="font-medium py-1">
                {currentUser?.email}
              </TableCell>
              <TableCell className="py-1">{payment?.status}</TableCell>
              <TableCell className="py-1 text-sm font-medium">
                <div className="flex items-center gap-2">
                  <img
                    src={StripeChannelSvg}
                    alt="stripe"
                    className="size-8"
                  />
                  {/* <span>{payment?.payment_channel}</span> */}
                </div>
              </TableCell>
              <TableCell className="py-1">
                {payment?.first_payment_date
                  ? moment
                      .unix(Number(payment?.first_payment_date))
                      .format("MM-DD-YYYY")
                  : ""}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}

      <AddPaymentModal
        open={openAddPaymentModal}
        teamId={teamId as string}
        onClose={() => {
          setOpenAddPaymentModal(false);
        }}
        callback={() => {
          setOpenAddPaymentModal(false);
          handleGetPayment();
        }}
      />
    </>
  );
};

export default PaymentMethod;
