import { Loader2 } from "lucide-react";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { cn } from "src/lib/utils";
import { setError } from "src/store/features/error";
import { RootState } from "src/store/store";
import { saveUserInfo } from "src/store/features/auth";
import { Header, Footer } from "src/layouts";
import { getUserProfile } from "src/services/user";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "src/shadcn/ui/dialog";

import SubscriptionPlanNow from "src/components/features/SubscriptionPlanNow";

type Props = {
  children: React.ReactNode;
};

const MainLayout: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const error = useSelector((state: RootState) => state.error.error);
  const loadingStatus = useSelector(
    (state: RootState) => state.loading.isLoading,
  );

  // functions
  const handleGetUserProfile = async () => {
    const res = await getUserProfile();

    if (res) {
      dispatch(saveUserInfo(res));
    }
  };

  const handleCloseErrorDialog = useCallback(() => {
    dispatch(setError(null));
  }, []);

  // effects
  useEffect(() => {
    handleGetUserProfile();
  }, []);

  return (
    <div className="min-h-screen flex flex-col max-w-screen-2xl mx-auto px-1 md:px-4">
      <Header />
      <main
        className={cn(
          "flex-1 flex flex-col py-5 md:py-10",
          loadingStatus && "hidden",
        )}
      >
        {children}
      </main>
      <div
        className={cn(
          "flex justify-center items-center flex-1",
          !loadingStatus && "hidden",
        )}
      >
        <Loader2 className="animate-spin" />
      </div>
      <Footer />

      <Dialog
        open={error?.errorStatus}
        onOpenChange={handleCloseErrorDialog}
      >
        <DialogContent className="w-[80vw] max-w-[80vw]">
          <DialogHeader>
            <DialogTitle>Limit job quota</DialogTitle>
            <DialogDescription>
              You have exceeded your current credit limit. Would you like to
              upgrade to a new plan?
            </DialogDescription>
          </DialogHeader>
          <SubscriptionPlanNow teamId={error?.teamId || ""} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MainLayout;
