import { Loader2 } from "lucide-react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { getTokenByCode } from "src/services/auth";
import { login as loginStore } from "src/store/features/auth";

const AuthCallback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const errorDescription =
    (searchParams.get("error_description") as string) || "";

  // functions
  const handleGetTokenByCode = async (code: string) => {
    const token = await getTokenByCode(code);

    if (token?.access_token) {
      dispatch(
        loginStore({
          accessToken: token.access_token,
          refreshToken: token.refresh_token,
          user: null,
        }),
      );
      setTimeout(() => {
        navigate("/");
      }, 500);
    } else {
      toast.error(errorDescription || "Failed to authenticate");
      navigate("/sign-in");
    }
  };

  // effects
  useEffect(() => {
    if (code) {
      handleGetTokenByCode(code);
    }
  }, [code]);

  return (
    <div className="h-full w-full flex items-center justify-center">
      <Loader2 className="mr-2 size-5 animate-spin" />
      <p className="text-lg">Authenticating...</p>
    </div>
  );
};

export default AuthCallback;
