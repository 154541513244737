import {
  ChangePassword,
  MyCompany,
  UpdateProfile,
} from "src/components/features";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/shadcn/ui/tab";

const Profile = () => {
  return (
    <div className="max-w-[1200px] mx-auto">
      <Tabs
        defaultValue="account"
        className=""
      >
        <TabsList className="grid w-full grid-cols-3">
          <TabsTrigger value="account">Account</TabsTrigger>
          <TabsTrigger value="company">My Company</TabsTrigger>
          <TabsTrigger value="password">Password</TabsTrigger>
        </TabsList>
        <TabsContent value="account">
          <UpdateProfile />
        </TabsContent>
        <TabsContent value="company">
          <MyCompany />
        </TabsContent>
        <TabsContent value="password">
          <ChangePassword />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Profile;
