import { IRulePassword } from "src/models";

export const MENU_ITEMS = [
  {
    id: "1",
    label: "Teams",
    path: "/teams",
  },
  {
    id: "2",
    label: "Subscription",
    path: "/subscription",
  },
  {
    id: "3",
    label: "Notifications",
    path: "/notifications",
  },
];

export const RULES_PASSWORD: IRulePassword[] = [
  {
    code: "LENGTH",
    message: "minium 8 characters",
    pattern: /^.{8,}$/,
    success: false,
  },
  {
    code: "NUMBER",
    message: "at least 1 number letter",
    pattern: /\d/,
    success: false,
  },
  {
    code: "LOWERCASE",
    message: "at least 1 lowercase letter",
    pattern: /[a-z]/,
    success: false,
  },
  {
    code: "UPPERCASE",
    message: "at least 1 uppercase letter",
    pattern: /[A-Z]/,
    success: false,
  },
  {
    code: "SPECIAL_CHAR",
    message: "at least 1 special character",
    pattern: /[!@#$%^&*()]/,
    success: false,
  },
];

export const FILE_UPLOAD_TYPE = {
  file: "file",
  text: "string",
};

export const CHOOSE_PLANS = [
  {
    title: "Free",
    price: "$0",
    desPrice: "forever",
    description: "Free resume scan",
    features: [
      "Review up to 3 resumes",
      "Detailed shortlist report",
      "One job every month",
      "Limited history retention",
      "Team of 1",
    ],
    actionLabel: "Subscribe Now",
    typeBtn: "default",
  },
  {
    title: "Standard",
    price: "$68.00",
    desPrice: "per month",
    description: "Cancel anytime",
    features: [
      "Review unlimited resumes",
      "Detailed shortlist report",
      "Unlimited jobs",
      "Unlimited history retention",
      "Up to 3 team members",
    ],
    actionLabel: "Subscribe Now",
    typeBtn: "default",
  },
  {
    title: "Premium",
    price: "$128.00",
    desPrice: "per month",
    description: "Cancel anytime",
    features: [
      "Review unlimited resumes",
      "Detailed shortlist report",
      "Unlimited jobs",
      "Unlimited history retention",
      "Up to 9 team members",
    ],
    actionLabel: "Subscribe Now",
    typeBtn: "default",
  },
  {
    title: "Enterprise",
    price: "$0",
    desPrice: "forever",
    description: "Custom requirements",
    features: [
      "Review unlimited resumes",
      "Detailed shortlist report",
      "Unlimited jobs",
      "Unlimited history retention",
      "Unlimited team members",
    ],
    actionLabel: "Contact us",
    typeBtn: "outline",
  },
];

export const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
