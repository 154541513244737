import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

interface LoadingState {
  isLoading: boolean;
  deleteJob: boolean;
}

const initialState: LoadingState = {
  isLoading: false,
  deleteJob: false,
};

export const loadingSlice = createSlice({
  name: "loadingStore",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    handleDeleteJob: (state, action: PayloadAction<boolean>) => {
      state.deleteJob = action.payload;
    },
  },
});

export const { setLoading, handleDeleteJob } = loadingSlice.actions;
export default loadingSlice.reducer;
