import { Loader2, Plus } from "lucide-react";

import { Table } from "@tanstack/react-table";
import { getTeams } from "src/services/team";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";

import { toast } from "sonner";
import { Input } from "src/shadcn/ui/input";
import { Button } from "src/shadcn/ui/button";
import { IJob, ITeam } from "src/models";
import { deleteJobById } from "src/services/job";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/shadcn/ui/select";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  callback?: () => void;
}

export function DataTableToolbar<TData>({
  table,
  callback,
}: DataTableToolbarProps<TData>) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const defaultTeamId = searchParams.get("team") || "";

  // states
  const [teams, setTeams] = useState<ITeam[]>([]);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  // functions
  const handleGetTeams = async () => {
    const res = await getTeams();

    if (res?.length > 0) {
      setTeams(res);
      const defaultTeam = res.find((r) => r.is_default);

      if (defaultTeam) {
        navigate(`${location.pathname}?team=${defaultTeam.id}`);
      }
    }
  };

  const handleDeleteJobById = async (jobId: string) => {
    setIsDeleteLoading(true);
    const res = await deleteJobById(jobId);
    setIsDeleteLoading(false);

    if (res === "success") {
      toast.success("Job deleted successfully");
      callback && callback();
    }
  };

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {};

  const handleDeleteJob = () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const selectedJobIds = selectedRows.map((row) => (row.original as IJob).id);

    if (selectedJobIds?.length > 1) {
      toast.warning("Please select only one job to delete");
      return;
    }

    handleDeleteJobById(selectedJobIds[0]);
  };

  const handleChangeTeam = (value: string) => {
    navigate(`${location.pathname}?team=${value}`);
  };

  // effects
  useEffect(() => {
    handleGetTeams();
  }, []);

  // ref, title, employer

  // memo
  const currentCols = table
    .getAllColumns()
    .map((column) => ({
      col: column.id,
      val: (column?.getFilterValue() as string) ?? "",
    }))
    .filter((c) => ["ref", "title", "employer"].includes(c.col));
  const currentSearch =
    currentCols.map((c) => c.val).find((c) => c.length > 0) ?? "";

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <div className="flex items-center gap-4">
          <Input
            placeholder="Filter jobs..."
            value={currentSearch}
            onChange={(event) =>
              table.getColumn("title")?.setFilterValue(event.target.value)
            }
            className="h-10 w-[200px] lg:w-[300px]"
          />
          <Select
            value={defaultTeamId}
            onValueChange={handleChangeTeam}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select a team" />
            </SelectTrigger>

            <SelectContent>
              {teams.map((team) => (
                <SelectItem
                  key={team.id}
                  value={team.id}
                >
                  {team.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex gap-3 justify-start md:justify-end w-full">
          <Button
            variant="ghost"
            className="font-medium bg-green-500 hover:bg-green-600 text-white hover:text-white border border-black dark:border-white border-2"
            onClick={() => navigate("/job-information/new")}
          >
            <Plus className="size-5 mr-2" />
            New Job
          </Button>
          <Button
            variant="destructive"
            disabled={
              table.getSelectedRowModel().rows.length === 0 || isDeleteLoading
            }
            onClick={handleDeleteJob}
            className="dark:bg-red-400 dark:disabled:opacity-80 border border-black dark:border-white border-2"
          >
            {isDeleteLoading && <Loader2 className="size-4 animate-spin" />}
            <span>Delete</span>
          </Button>
        </div>
      </div>
      {/* <DataTableViewOptions table={table} /> */}
    </div>
  );
}
