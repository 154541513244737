import { handleGlobalError } from "src/lib/utils";
import { IUser } from "src/models";
import api from "src/services/api";

export const getUserProfile = async (): Promise<IUser | undefined> => {
  try {
    const res = await api.get("/user/profile");
    return res.data?.user || {};
  } catch (error) {
    handleGlobalError(error);
  }
};

export const changePassword = async (account: {
  old_password: string;
  new_password: string;
}) => {
  try {
    const res = await api.post("/auth/change-password", {
      old_password: account.old_password,
      new_password: account.new_password,
    });

    if (res.status === 200) {
      return "success";
    }

    return "fail";
  } catch (error) {
    handleGlobalError(error);
    return "error";
  }
};

export const updateProfile = async (account: {
  first_name: string;
}): Promise<IUser | undefined> => {
  try {
    const res = await api.post("/user/profile", account);
    return res?.data?.user;
  } catch (error) {
    handleGlobalError(error);
    return undefined;
  }
};
