import React from "react";

import { Route, Routes, BrowserRouter as UserRouter } from "react-router-dom";

import { Toaster } from "./shadcn/ui/toaster";
import { PrivateRouter } from "./components/common";
import { Toaster as ToasterSonner } from "src/shadcn/ui/sonner";
import { PRIVATE_ROUTER, PUBLIC_ROUTER } from "./router";

import NotFound from "./pages/NotFound";

function App() {
  return (
    <React.Fragment>
      <UserRouter>
        <Routes>
          {PUBLIC_ROUTER.map((route) => {
            const Page = route.page;
            const Layout = route.layout;

            return (
              <Route
                key={route.id}
                path={route.path}
                element={
                  <Layout>
                    <Page />
                  </Layout>
                }
              />
            );
          })}
          {PRIVATE_ROUTER.map((route) => {
            const Page = route.page;
            const Layout = route.layout;

            return (
              <Route
                key={route.id}
                path={route.path}
                element={
                  <PrivateRouter>
                    <Layout>
                      <Page />
                    </Layout>
                  </PrivateRouter>
                }
              />
            );
          })}
          <Route
            path="*"
            element={<NotFound />}
          />
        </Routes>
      </UserRouter>

      <Toaster />
      <ToasterSonner richColors />
    </React.Fragment>
  );
}

export default App;
