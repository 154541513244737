import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { cn } from "src/lib/utils";
import { Button } from "src/shadcn/ui/button";
import { ScrollArea } from "src/shadcn/ui/scroll-area";
import { Sheet, SheetContent, SheetTrigger } from "src/shadcn/ui/sheet";

const CustomLinkRouter = ({
  label,
  path,
  onOpenChange,
}: {
  label: string;
  path: string;
  onOpenChange: () => void;
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(path);
    onOpenChange();
  };

  return (
    <div onClick={handleRedirect}>
      <span
        className={cn(
          "transition-colors hover:text-foreground/80",
          pathname === path ? "text-foreground" : "text-foreground/60",
        )}
      >
        {label}
      </span>
    </div>
  );
};

export function MobileNav() {
  const [open, setOpen] = useState(false);

  const handleOpenChange = () => {
    setOpen(false);
  };

  return (
    <Sheet
      open={open}
      onOpenChange={setOpen}
    >
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          className="mr-2 px-0 text-base hover:bg-transparent focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 md:hidden"
        >
          <svg
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
          >
            <path
              d="M3 5H11"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M3 12H16"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M3 19H21"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
          <span className="sr-only">Toggle Menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent
        side="left"
        className="pr-0"
      >
        <ScrollArea className="my-4 h-[calc(100vh-8rem)] pb-10 pl-6">
          <nav className="flex flex-col gap-4 text-sm lg:text-base lg:gap-6">
            <CustomLinkRouter
              path="/teams"
              label="Teams"
              onOpenChange={handleOpenChange}
            />
            <CustomLinkRouter
              path="/subscription"
              label="Subscription"
              onOpenChange={handleOpenChange}
            />
          </nav>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
}
