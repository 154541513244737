import { z } from "zod";
import { toast } from "sonner";
import { useForm } from "react-hook-form";
import { Loader2 } from "lucide-react";
import { RootState } from "src/store/store";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Input } from "src/shadcn/ui/input";
import { Button } from "src/shadcn/ui/button";
import { saveUserInfo } from "src/store/features/auth";
import { updateProfile } from "src/services/user";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "src/shadcn/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/shadcn/ui/form";

const schema = z.object({
  firstLastName: z
    .string()
    .min(1, { message: "First & Last Name is required." }),
  email: z.string().email({ message: "Invalid email address." }),
});

const UpdateProfile = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      firstLastName: "",
      email: "",
    },
  });

  // states
  const [isLoading, setIsLoading] = useState(false);

  // functions
  const handleUpdateProfile = async (firstName: string) => {
    setIsLoading(true);
    const res = await updateProfile({ first_name: firstName });
    setIsLoading(false);

    if (res) {
      dispatch(saveUserInfo(res));
      toast.success("Profile updated successfully");
    }
  };

  const onSubmit = (values: z.infer<typeof schema>) => {
    handleUpdateProfile(values.firstLastName);
  };

  // effects
  useEffect(() => {
    if (currentUser) {
      form.setValue("firstLastName", currentUser.first_name);
      form.setValue("email", currentUser.email);
    }
  }, [currentUser]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Account Details</CardTitle>
        <CardDescription>
          Make changes to your account here. Click save when you're done.
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-2">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="space-y-4">
              <FormField
                control={form.control}
                name="firstLastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>First & Last Name</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Enter your name"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Enter your email"
                        {...field}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex justify-end">
                <Button
                  type="submit"
                  disabled={isLoading}
                  className="flex items-center gap-1"
                >
                  {isLoading && <Loader2 className="h-4 w-4 animate-spin" />}
                  <span>Save Changes</span>
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
};

export default UpdateProfile;
