import { CheckCircle2 } from "lucide-react";

import { cn } from "src/lib/utils";

const CheckItem = ({
  text,
  className,
}: {
  text: string;
  className?: string;
}) => (
  <div className={cn("flex gap-2", className && className)}>
    <CheckCircle2
      size={18}
      className="my-auto text-green-400"
    />
    <p className="pt-0.5 text-zinc-700 dark:text-zinc-300 text-sm">{text}</p>
  </div>
);

export default CheckItem;
