import { z } from "zod";
import { useForm } from "react-hook-form";
import { Loader2 } from "lucide-react";
import React, { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";

import { Input } from "src/shadcn/ui/input";
import { Button } from "src/shadcn/ui/button";
import { createTeam } from "src/services/team";
import { Card, CardContent } from "src/shadcn/ui/card";
import { newTeamFormSchema } from "src/models/form-schema";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/shadcn/ui/form";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "src/shadcn/ui/dialog";

interface Props {
  id?: string;
  callback?: () => void;
}

const TeamFormModal: React.FC<Props> = ({ id, callback }) => {
  // state
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<z.infer<typeof newTeamFormSchema>>({
    resolver: zodResolver(newTeamFormSchema),
    defaultValues: {
      name: "",
    },
  });

  // functions
  const handleCreateTeam = async (name: string) => {
    setIsLoading(true);
    const res = await createTeam({ name });
    setIsLoading(false);

    if (res === "ok") {
      if (callback) {
        callback();
      }
      setOpenModal(false);
      form.reset();
    }
  };

  const onSubmit = (values: z.infer<typeof newTeamFormSchema>) => {
    handleCreateTeam(values.name);
  };

  return (
    <div className="mt-4">
      <Button onClick={() => setOpenModal(true)}>
        {id ? "Edit Team" : "Add New Team"}
      </Button>
      <Dialog
        open={openModal}
        onOpenChange={() => setOpenModal(false)}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-base text-left">
              {id ? "Edit Team" : "Add New Team"}
            </DialogTitle>
          </DialogHeader>
          <Card className="w-full max-w-[450px]">
            <CardContent className="p-4">
              <div className="w-full">
                <Form {...form}>
                  <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-6"
                  >
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Team Name *</FormLabel>
                          <FormControl>
                            <Input
                              type="text"
                              placeholder="Enter your team name"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage className="text-xs" />
                        </FormItem>
                      )}
                    />

                    <Button
                      type="submit"
                      className="w-full mb-8"
                      disabled={isLoading || !form.formState.isValid}
                    >
                      {isLoading && (
                        <Loader2 className="mr-2 size-5 animate-spin" />
                      )}
                      Save
                    </Button>
                  </form>
                </Form>
              </div>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TeamFormModal;
