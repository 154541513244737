import { cn } from "src/lib/utils";

type Props = {
  className?: string;
};

const SeparatorOr: React.FC<Props> = ({ className }) => {
  return (
    <div
      className={cn(
        "relative w-full flex items-center justify-center after:content-[''] after:w-full after:h-[1px] after:bg-[#e2e8f0] after:inline-block after:absolute after:top-1/2 after:z-0",
        className && className,
      )}
    >
      <div className="z-10 bg-white dark:bg-black text-center inline-block px-2 text-sm font-medium">
        OR
      </div>
    </div>
  );
};

export default SeparatorOr;
